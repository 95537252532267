import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { GetAdviceDataState } from '@/models/getAdviceData'
import { Expense, ExpenseIdentifier } from '@/models/expenses'
import { _expenseUiConfigurations } from './ui/privateData'
const initialState: GetAdviceDataState = {
    expenses: [
        {
            identifier: ExpenseIdentifier.Accommodation,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.Groceries,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.Transport,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.Communication,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.Medical,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.Insurance,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.DebtRepayment,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.EntertainmentHobbies,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
        {
            identifier: ExpenseIdentifier.Other,
            label: null,
            originalMonthlyCost: null,
            currentMonthlyCost: null,
            percentageReduction: null,
        },
    ],
    afCurrentSavings: null,
    afMonthlyContribution: null,
    salary: null,
    pensionableSalary: null,
    fundFees: null,
    otherCurrentSavings: null,
    otherMonthlyContribution: null,
    monthlyContributions: null,
    predictedTotalSavings: null,
    jobInitialIncome: null,
    ageDone: null,
    cashAvailable: null,
    propertyAvailable: null,
    sharesAvailable: null,
    retirementAge: null,
    guaranteedIncome: null,
    flexibleIncome: null,
}

export const state = () => ({ ...initialState })

export const getters: GetterTree<GetAdviceDataState, RootState> = {
    getState: (state, __, _, rootGetters) => ({
        ...state,
        guaranteedNetIncome: rootGetters['retirementCalculator/getNetRetirementSavings'] / 12,
        retirementAge: Number(state.retirementAge),
        ageDone: Number(state.ageDone),
        pensionableSalary: state.salary! * (state.pensionableSalary! / 100),
        fundFees: state.afMonthlyContribution! * (state.fundFees! / 100),
        fundFeesPercentage: state.fundFees! / 100,
        fundSalaryPercentage: rootGetters['incomes/getSalaryInfo'].fundSalaryPercentage!,
    }),
    getPercentageToGuaranteed: state => state.guaranteedIncome,
    getRetirementSavings: state => {
        return {
            afCurrentSavings: state.afCurrentSavings,
            otherCurrentSavings: state.otherCurrentSavings,
            predictedTotalSavings: state.predictedTotalSavings,
        }
    },
    getSalaryAndContributions: state => {
        return {
            salary: state.salary,
            afMonthlyContribution: state.afMonthlyContribution,
            pensionableSalary: state.pensionableSalary,
            fundFees: state.fundFees,
        }
    },
    getExpenses: state => state.expenses,
    getStrategyData: state => {
        return {
            jobInitialIncome: state.jobInitialIncome,
            ageDone: state.ageDone,
            cashAvailable: state.cashAvailable,
            propertyAvailable: state.propertyAvailable,
            sharesAvailable: state.sharesAvailable,
            retirementAge: state.retirementAge,
        }
    },
    getIncomes: state => {
        return {
            flexibleIncome: state.flexibleIncome,
            guaranteedIncome: state.guaranteedIncome,
        }
    },
}

export const actions: ActionTree<GetAdviceDataState, RootState> = {
    setRetirementSavings({ commit }, savingsForm) {
        commit(SET_RETIREMENT_SAVINGS, savingsForm)
    },
    setSalaryAndContribution({ commit }, incomesForm) {
        commit(SET_SALARY_CONTRIBUTIONS, incomesForm)
    },
    initializeExpenses({ commit }, expenses: Expense[]) {
        commit(INITIALIZE_EXPENSES, expenses)
    },
    setExpenses({ commit }, expenses: any[]) {
        commit(SET_EXPENSES, expenses)
    },
    setStrategyData({ commit }, strategyData) {
        commit(SET_STRATEGY_DATA, strategyData)
    },
    setIncomes({ commit }, incomes) {
        commit(SET_INCOMES, incomes)
    },
}

export const SET_EXPENSES = 'SET_EXPENSES'
export const SET_RETIREMENT_SAVINGS = 'SET_RETIREMENT_SAVINGS'
export const SET_SALARY_CONTRIBUTIONS = 'SET_SALARY_CONTRIBUTIONS'
export const INITIALIZE_EXPENSES = 'INITIALIZE_ADVICE_STORE'
export const SET_STRATEGY_DATA = 'SET_STRATEGY_DATA'
export const SET_INCOMES = 'SET_INCOMES'

export const mutations: MutationTree<GetAdviceDataState> = {
    [SET_RETIREMENT_SAVINGS](state, savingsForm) {
        state.afCurrentSavings = savingsForm.afCurrentSavings
        state.otherCurrentSavings = savingsForm.otherCurrentSavings
        state.predictedTotalSavings = savingsForm.predictedTotalSavings
        state.otherMonthlyContribution = savingsForm.otherMonthlyContribution
        state.monthlyContributions = savingsForm.monthlyContributions.unit === 'PERCENTAGE' ?
            savingsForm.monthlyContributions.amount / 100 :
            savingsForm.monthlyContributions.amount / savingsForm.salary
    },
    [SET_SALARY_CONTRIBUTIONS](state, incomesForm) {
        state.afMonthlyContribution = incomesForm.afMonthlyContribution
        state.salary = incomesForm.salary
        state.pensionableSalary = incomesForm.pensionableSalary
        state.fundFees = incomesForm.fundFees
    },
    [INITIALIZE_EXPENSES](state, expenses: Expense[]) {
        // iterate throug the expenses and then overwrite the expenses in this store with relevant values
        expenses.forEach(value => {
            if (value) {
                const expenseIndex = state.expenses.findIndex(x => x.identifier === value.identifier)
                state.expenses[expenseIndex].currentMonthlyCost = value.monthlyCost
                state.expenses[expenseIndex].originalMonthlyCost = value.originalMonthlyCost || null
                const percentageReduced = Math.round(100 - (value.monthlyCost! / value.originalMonthlyCost!) * 100)
                state.expenses[expenseIndex].percentageReduction = percentageReduced
                state.expenses[expenseIndex].label = _expenseUiConfigurations.find(
                    x => x.identifier === value.identifier,
                )!.label
            }
        })
    },
    [SET_EXPENSES](state, expenses: any[]) {
        state.expenses = expenses
    },
    [SET_STRATEGY_DATA](state, strategyData) {
        state.jobInitialIncome = strategyData.jobInitialIncome
        state.ageDone = strategyData.ageDone
        state.cashAvailable = strategyData.cashAvailable
        state.propertyAvailable = strategyData.propertyAvailable
        state.sharesAvailable = strategyData.sharesAvailable
        state.retirementAge = strategyData.retirementAge
    },
    [SET_INCOMES](state, incomes) {
        state.flexibleIncome = incomes.flexibleIncome
        state.guaranteedIncome = incomes.guaranteedIncome
    },
}
