// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.
// Copied from https://github.com/chrisvfritz/vue-enterprise-boilerplate/blob/master/src/components/_globals.js

import Vue from 'vue'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    // Look for files in the current directory <-- tweaked for our filestructure
    '@/components/BaseComponents',
    // Do not look in subdirectories
    false,
    // Only include "_base-" prefixed .vue files
    /Base[\w-]+\.vue$/, // Only part unique for this Codebase.
)

// For each matching file name...
requireComponent.keys().forEach(fileName => {
    // Get the component config
    const componentConfig = requireComponent(fileName)
    // Get the PascalCase version of the component name
    const componentName = fileName
        // Remove the "./" from the beginning <-- Also tweaked for our naming convention
        .replace(/^\.\//, '')
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
        // Split up kebabs
        .split('-')
        // Upper case
        .map(kebab => kebab.charAt(0).toUpperCase() + kebab.slice(1))
        // Concatenated
        .join('')

    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig)
})
