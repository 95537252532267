import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { UiState } from '@/models/ui'
import { RootState } from '@/store'
import { _expenseUiConfigurations } from '@/store/ui/privateData'
import { Expense, ExpenseIdentifier, ExpenseUiConfiguration } from '@/models/expenses'
import { Section } from '@/models/sections'

const initialState: UiState = {
    visibleModals: [],
    onceOffButSeenModals: [],
    showSliderPrompt: true,
    nextSection: Section.ABOUT_ME,
    introSubpage: 0,
}

export const state = () => ({ ...initialState })

export const getters: GetterTree<UiState, RootState> = {
    mustShowModal: state => (modalComponentName: string) =>
        state.visibleModals.includes(modalComponentName) && !state.onceOffButSeenModals.includes(modalComponentName),
    // Using any for rootState, because it seems TS sees RootState as an empty object. Sort of makes sense, because rootState is such a dynamic object <-- it grows every time we add state to any module. Suppose if we're careful about the typings of what we put into rootState, then rootState being any is zero-harm.
    expenseUiConfigurations: (_, __, rootState: any) => {
        return rootState.expenses.priorities.map((identifier: string) =>
            _expenseUiConfigurations.find(expense => expense.identifier === identifier),
        )
    },
    // Represents UiConfigurations only for expenses that have been created by the user.
    userAssignedExpenseUiConfigurations: (_, getters, rootState: any) =>
        getters.expenseUiConfigurations.filter((configuration: ExpenseUiConfiguration) =>
            rootState.expenses.items.find((expense: Expense) => expense.identifier === configuration.identifier),
        ),
    getExpenseUiConfiguration: (_, getters) => (identifier: ExpenseIdentifier) =>
        getters.expenseUiConfigurations.find((x: ExpenseUiConfiguration) => x.identifier === identifier),
    // For Intro Content and Disclaimer
    mustShowLandscapeBackground: state => state.introSubpage > 0,
}

export const actions: ActionTree<UiState, RootState> = {
    resetAllModalsAsUnseen({ commit }) {
        commit(RESET_SEEN_MODALS)
    },
    resetVisibleModals({ commit }) {
        commit(RESET_VISIBLE_MODALS)
    },
    showModal({ commit }, modalComponentName) {
        commit(SHOW_MODAL, modalComponentName)
    },
    hideModal({ commit }, modalComponentName) {
        commit(HIDE_MODAL, modalComponentName)
    },
    hideModals({ commit }, modalComponentNames) {
        commit(HIDE_MODAL, modalComponentNames)
    },
    hasDraggedSliderOnce({ commit }) {
        commit(SLIDER_PROMPT_DRAGGED_ONCE)
    },
    addModalToHasSeenList({ commit }, modalComponentName) {
        commit(ADD_MODAL_TO_HAS_SEEN_LIST, modalComponentName)
    },
    setNextSection({ commit }, nextSection) {
        commit(SET_NEXT_SECTION, nextSection)
    },
    setIntroSubpage({ commit }, pageNumber) {
        commit(SET_INTRO_SUBPAGE, pageNumber)
    },
}

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const HIDE_MODALS = 'HIDE_MODALS'
export const ADD_MODAL_TO_HAS_SEEN_LIST = 'ADD_MODAL_TO_HAS_SEEN_LIST'
export const RESET_SEEN_MODALS = 'RESET_SEEN_MODALS'
export const RESET_VISIBLE_MODALS = 'RESET_VISIBLE_MODALS'
export const SLIDER_PROMPT_DRAGGED_ONCE = 'SLIDER_PROMPT_DRAGGED_ONCE'
export const SET_NEXT_SECTION = 'SET_NEXT_SECTION'
export const SET_INTRO_SUBPAGE = 'SET_INTRO_SUBPAGE'

export const mutations: MutationTree<UiState> = {
    [SHOW_MODAL](state, modalComponentName: string) {
        state.visibleModals.push(modalComponentName)
    },
    [HIDE_MODAL](state, modalComponentName: string) {
        state.visibleModals = state.visibleModals.filter(modal => modal !== modalComponentName)
    },
    [HIDE_MODALS](state, modalComponentNames: string[]) {
        state.visibleModals = state.visibleModals.filter(modal =>
            modalComponentNames.find(modalComponentName => modal === modalComponentName),
        )
    },
    [ADD_MODAL_TO_HAS_SEEN_LIST](state, modalComponentName: string) {
        state.onceOffButSeenModals.push(modalComponentName)
    },
    [SLIDER_PROMPT_DRAGGED_ONCE](state) {
        state.showSliderPrompt = false
    },
    [RESET_SEEN_MODALS](state) {
        state.onceOffButSeenModals = []
    },
    [RESET_VISIBLE_MODALS](state) {
        state.visibleModals = []
    },
    [SET_NEXT_SECTION](state, nextSection: Section) {
        state.nextSection = nextSection
    },
    [SET_INTRO_SUBPAGE](state, pageNumber) {
        state.introSubpage = pageNumber
    },
}
