import { GetterTree, ActionTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { ExpensesState, ExpenseIdentifier, Expense } from '@/models/expenses'
import { RootState } from '@/store'
import { calculateInflation, calculateMedicalInflation, MAX_AGE } from '@/helpers/inflation'
import {
    _sliderCardTextStep4Landscape,
    _sliderCardTextStep5Landscape,
    _sliderCardTextStep7Landscape,
} from '@/store/ui/privateData'
const DEFAULT_PRIORITIES = [
    ExpenseIdentifier.Accommodation,
    ExpenseIdentifier.Groceries,
    ExpenseIdentifier.Transport,
    ExpenseIdentifier.Communication,
    ExpenseIdentifier.Medical,
    ExpenseIdentifier.Insurance,
    ExpenseIdentifier.DebtRepayment,
    ExpenseIdentifier.EntertainmentHobbies,
    ExpenseIdentifier.Other,
]
const initialState: ExpensesState = {
    isAddingExpenses: false,
    items: [],
    priorities: [...DEFAULT_PRIORITIES],
    guaranteedExpenses: [],
}
export const state = () => ({ ...initialState })

export const getters: GetterTree<ExpensesState, RootState> = {
    isAddingExpenses: state => state.isAddingExpenses,
    getExpenses: state =>
        state.priorities.map((identifier: ExpenseIdentifier) =>
            state.items.find(expense => expense.identifier === identifier),
        ),
    getExpense: state => (identifier: ExpenseIdentifier) => state.items.find(x => x.identifier === identifier),
    getBudgetSize: state => (identifier: ExpenseIdentifier) =>
        state.items.find(x => x.identifier === identifier)?.budgetSize,
    totalExpensesCost: state =>
        state.items
            .filter(x => x.monthlyCost != null)
            .reduce((total, expense) => {
                return total + expense.monthlyCost!
            }, 0),
    getInflationRange: (state, __, _, rootGetters) => (identifier: ExpenseIdentifier) => {
        let monthlyCost = state.items.find(x => x.identifier === identifier)?.monthlyCost
        if (!monthlyCost) {
            monthlyCost = 1000
        }
        const numberOfyears = MAX_AGE - rootGetters['user/getAge']

        return calculateInflation(numberOfyears, monthlyCost)
    },
    getInflationRangeMedical: (state, __, _, rootGetters) => (identifier: ExpenseIdentifier) => {
        let monthlyCost = state.items.find(x => x.identifier === identifier)?.monthlyCost
        if (!monthlyCost) {
            monthlyCost = 2000
        }
        return calculateMedicalInflation(rootGetters['user/getAge'], MAX_AGE, monthlyCost)
    },
    isGuaranteed: state => (identifier: ExpenseIdentifier) => state.guaranteedExpenses.includes(identifier),
    getSliderCardText: _ => (item: { identifier: ExpenseIdentifier; step: number }) => {
        if (item.step === 4) {
            return _sliderCardTextStep4Landscape.find(expense => expense.identifier === item.identifier)!.text
        }
        if (item.step === 5) {
            return _sliderCardTextStep5Landscape.find(expense => expense.identifier === item.identifier)!.text
        }
        if (item.step === 7 || item.step === 6) {
            return _sliderCardTextStep7Landscape.find(expense => expense.identifier === item.identifier)!.text
        }
    },
}
export const actions: ActionTree<ExpensesState, RootState> = {
    startAddingExpenses({ commit }) {
        commit(IS_ADDING_EXPENSES, true)
    },
    cleanExpenses({ commit }) {
        commit(CLEAN_EXPENSES)
    },
    finishAddingExpenses({ commit, dispatch }) {
        // `root: true` allows us to call namespaced actions
        dispatch('ui/resetVisibleModals', null, { root: true })
        commit(IS_ADDING_EXPENSES, false)
    },
    setExpense({ commit }, expense: Expense) {
        commit(SET_EXPENSE, expense)
    },
    setBudgetSize({ commit }, budget) {
        commit(SET_BUDGET_SIZE, budget)
    },
    deleteExpense({ commit }, identifier: ExpenseIdentifier) {
        commit(DELETE_EXPENSE, identifier)
    },
    prioritiseExpenses({ commit }, prioritisedList: ExpenseIdentifier[]) {
        commit(PRIORITISE_EXPENSES, prioritisedList)
    },
    resetState({ commit }) {
        commit(RESET_STATE)
    },
    guaranteeExpense({ commit }, identifier: ExpenseIdentifier) {
        commit(GUARANTEE_EXPENSE, identifier)
    },
    unGuaranteeExpense({ commit }, identifier: ExpenseIdentifier) {
        commit(UNGUARANTEE_EXPENSE, identifier)
    },
    clearGuaranteedExpenses({ commit }) {
        commit(CLEAR_GUARANTEED_EXPENSES)
    },
}

export const IS_ADDING_EXPENSES = 'IS_ADDING_EXPENSES'
export const SET_EXPENSE = 'SET_EXPENSE'
export const SET_BUDGET_SIZE = 'SET_BUDGET_SIZE'
export const SHOW_DONE_MODAL = 'SHOW_DONE_MODAL'
export const SHOW_AGE_YEAR = 'SHOW_AGE_YEAR'
export const RESET_STATE = 'RESET_STATE'
export const DELETE_EXPENSE = 'DELETE_EXPENSE'
export const CLEAN_EXPENSES = 'CLEAN_EXPENSES'
export const PRIORITISE_EXPENSES = 'PRIORITISE_EXPENSES'
export const GUARANTEE_EXPENSE = 'GUARANTEE_EXPENSE'
export const UNGUARANTEE_EXPENSE = 'UNGUARANTEE_EXPENSE'
export const CLEAR_GUARANTEED_EXPENSES = 'CLEAR_GUARANTEED_EXPENSES'

export const mutations: MutationTree<ExpensesState> = {
    [IS_ADDING_EXPENSES](state, canDisplay: boolean) {
        state.isAddingExpenses = canDisplay
    },
    [CLEAN_EXPENSES](state) {
        state.items = state.items.filter(item => item.monthlyCost !== null)
    },
    [SET_EXPENSE](state, expense: Expense) {
        const index = state.items.findIndex(x => x.identifier === expense.identifier)
        if (index === -1) {
            state.items.push(expense)
        } else {
            Vue.set(state.items, index, expense)
        }
    },
    [PRIORITISE_EXPENSES](state, prioritisedList: ExpenseIdentifier[]) {
        state.priorities = prioritisedList
    },
    [SET_BUDGET_SIZE](state, budget) {
        const index = state.items.findIndex(x => x.identifier === budget.identifier)
        if (index !== -1) {
            Vue.set(state.items[index], 'budgetSize', budget.budgetSize)
        }
    },
    [DELETE_EXPENSE](state, identifier: ExpenseIdentifier) {
        const index = state.items.findIndex(x => x.identifier === identifier)
        if (index !== -1) {
            state.items.splice(index, 1)
        }
    },
    [RESET_STATE](state) {
        Object.assign(state, { ...initialState })
    },
    [GUARANTEE_EXPENSE](state, identifier: ExpenseIdentifier) {
        state.guaranteedExpenses.push(identifier)
    },
    [UNGUARANTEE_EXPENSE](state, identifier: ExpenseIdentifier) {
        state.guaranteedExpenses = state.guaranteedExpenses.filter(expense => expense !== identifier)
    },
    [CLEAR_GUARANTEED_EXPENSES](state) {
        state.guaranteedExpenses = []
    },
}
