<template>
    <div id="no-breadcrumb-layout">
        <div class="header-wrapper">
            <img style="display: inline-block" src="@/assets/alexander_forbes_logo@4x.png" width="213px" alt="" />
            <h2 class="title">My Retirement Picture</h2>
            <div class="grey-bar">
                <div class="breadcrumb">
                    <div
                        class="bar-title"
                        :class="{
                            active: currentRoute > 0,
                        }"
                        style="width: 97px"
                        @click="$router.push('/introduction/1')"
                    >
                        Introduction
                    </div>
                    <div class="arrow-right">
                        <img width="23" height="30" src="Step_0_nav_bar_breadcrumb.png" alt="" />
                    </div>
                    <div
                        class="bar-title"
                        :class="{
                            active: !(currentRoute > 0),
                        }"
                        style="width: 85px"
                        @click="$router.push('/')"
                    >
                        Main Menu
                    </div>
                </div>
            </div>
        </div>
        <div class="content-pane">
            <div v-show="mustShowLandscapeBackground" id="landscape-background">
                <div class="view-box">
                    <img class="landscape-image" width="100%" src="landscape_gradient.png" alt="" />
                </div>
            </div>
            <div class="page-content">
                <nuxt />
            </div>
        </div>
        <base-navigator
            v-if="currentRoute > 0"
            id="intro-nav"
            :class="{
                hide: !showPrevious,
            }"
            @previous="goPrevious"
            @next="goNext"
        />
        <please-rotate-modal :value="mustShowModal(Modal.PleaseRotate)" />
        <please-bigger-screen-modal :value="mustShowModal(Modal.PleaseBiggerScreen)" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PleaseRotateModal from '@/components/Modals/PleaseRotateModal.vue'
import PleaseBiggerScreenModal from '@/components/Modals/PleaseBiggerScreenModal.vue'
import { Modal } from '@/models/ui'
import { BreakPointLimits } from '@/helpers/constants'
export default {
    components: {
        PleaseRotateModal,
        PleaseBiggerScreenModal,
    },
    data() {
        return {
            Modal,
            currentRoute: '',
            showPrevious: true,
        }
    },
    computed: {
        ...mapGetters('ui', ['mustShowModal', 'mustShowLandscapeBackground']),
    },
    watch: {
        $route: {
            async handler(to) {
                if (!to.name.includes('-')) {
                    this.currentRoute = 0
                } else {
                    this.currentRoute = Number(to.name.split('-')[1])
                }
                if (this.currentRoute === 1) {
                    this.showPrevious = false
                } else {
                    this.showPrevious = true
                }
                await this.$store.dispatch('ui/setIntroSubpage', this.currentRoute)
            },
            immediate: true,
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            const widthOfShortestSideOfIpad = BreakPointLimits.MIN_WIDTH
            const heightOfSmallestLaptop = BreakPointLimits.MIN_HEIGHT
            const isTooSmall =
                window.innerWidth < widthOfShortestSideOfIpad || window.innerHeight < heightOfSmallestLaptop
            if (isTooSmall) {
                // Even though we have an early return for isTooSmall;
                // This hiding of PleaseRotate covers the case of a tester who moves from portrait to isTooSmall.
                this.$store.dispatch('ui/hideModal', Modal.PleaseRotate)
                this.$store.dispatch('ui/showModal', Modal.PleaseBiggerScreen)
                // The return ensures that this modal gets prioritised over the isPortrait based one.
                return
            } else {
                this.$store.dispatch('ui/hideModal', Modal.PleaseBiggerScreen)
            }

            const isPortrait = window.innerWidth < window.innerHeight
            if (isPortrait) {
                this.$store.dispatch('ui/showModal', Modal.PleaseRotate)
            } else {
                this.$store.dispatch('ui/hideModal', Modal.PleaseRotate)
            }
        },
        goPrevious() {
            this.$router.push(`/introduction/${this.currentRoute - 1}`)
        },
        goNext() {
            if (this.currentRoute === 4) {
                this.$router.push('/')
            } else {
                this.$router.push(`/introduction/${this.currentRoute + 1}`)
            }
        },
    },
}
</script>
<style scoped>
#no-breadcrumb-layout {
    overflow: hidden;
}
.landscape-background {
    position: relative;
}
.content-pane {
    position: relative;
    --toolbar-height: 135px;
    height: calc(100vh - var(--toolbar-height));
}
.page-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-box {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#def9f7, #d1f6f4);
    overflow: hidden;
}

.landscape-image {
    transform: translateY(-3%);
    /* margin-bottom: 500px; */
}

.header-wrapper {
    padding: 20px 85px 0 85px;
    height: 100%;
}
.grey-bar {
    --grey-bar-height: 49px;
    margin-top: 20px;
    margin-left: -85px;
    width: 100vw;
    height: var(--grey-bar-height);
    background-color: #e2e2e2;
}
.breadcrumb {
    display: flex;
    margin-left: 85px;
    height: 100%;
}
.bar-title {
    font-family: 'Source Sans Pro';
    font-size: 17px;
    /* Setting line-height to the same as parent's height results in vertically centered text. */
    line-height: var(--grey-bar-height);
    color: #606060;
    text-align: center;
    cursor: pointer;
    /* transition: font-weight 500ms ease-out; */
}
.bar-title.active {
    font-size: 17px;
    font-weight: bold;
    /* transition: font-weight 500ms ease-out; */
    /* color: #606060; */
}
.grey-bar .arrow-right {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 17px;
}
.title {
    margin-left: 50px;
    display: inline-block;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 25px;
    color: #606060;
}
</style>
<style>
html {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
}

.button--green {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #3b8070;
    color: #3b8070;
    text-decoration: none;
    padding: 10px 30px;
}

.button--green:hover {
    color: #fff;
    background-color: #3b8070;
}

.button--grey {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #35495e;
    color: #35495e;
    text-decoration: none;
    padding: 10px 30px;
    margin-left: 15px;
}

.button--grey:hover {
    color: #fff;
    background-color: #35495e;
}

/** Mainly overriding navigator styles to hide previous button when on first intro subpage. */
#no-breadcrumb-layout .hide .base-button.navigator-previous {
    display: none;
}
#no-breadcrumb-layout .hide .base-button {
    font-family: 'Source Sans Pro';
}
</style>
