<template>
    <div class="tooltip-box">
        <img
            v-click-outside="hideHint"
            class="hint"
            src="@/assets/tool_tip/popin_button.png"
            alt="tool tip"
            @click="showHint = !showHint"
        />

        <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <div v-if="showHint" :style="cssVars" class="tooltip">
                <img
                    src="@/assets/tool_tip/popin_close.png"
                    class="close-hint"
                    alt="Close Tool Tip"
                    @click="showHint = false"
                />
                <img src="@/assets/tool_tip/popin_ear.png" :class="getArrowPosition" alt="Tool Tip Arrow" />
                <div class="text" :class="getBoxPosition" v-html="text"></div>
            </div>
        </transition>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
    directives: {
        ClickOutside,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            default: 300,
        },
        position: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showHint: false,
        }
    },
    computed: {
        cssVars() {
            const halfWidth = this.width / 2

            const ToolTipPositions = {
                middleBottom: {
                    '--width': this.width + 'px',
                    '--box-placement': '-' + halfWidth + 'px',
                    '--arrow-placement': '50%',
                    top: '145%',
                },
                middleBottomLeft: {
                    '--width': this.width + 'px',
                    '--box-placement': '-' + this.width / 5 + 'px',
                    '--arrow-placement': '20%',
                    top: '145%',
                },
                middleBottomRight: {
                    '--width': this.width + 'px',
                    '--box-placement': '-' + (this.width - this.width / 5) + 'px',
                    '--arrow-placement': '80%',
                    top: '145%',
                },
                middleTop: {
                    '--width': this.width + 'px',
                    '--box-placement': '-' + halfWidth + 'px',
                    '--arrow-placement': '50%',
                    bottom: '150%',
                },
                middleTopLeft: {
                    '--width': this.width + 'px',
                    '--box-placement': '-' + this.width / 5 + 'px',
                    '--arrow-placement': '20%',
                    bottom: '150%',
                },
                middleTopRight: {
                    '--width': this.width + 'px',
                    '--box-placement': '-' + (this.width - this.width / 5) + 'px',
                    '--arrow-placement': '80%',
                    bottom: '150%',
                },
            }

            return ToolTipPositions[this.position]
        },
        getBoxPosition() {
            if (this.position.includes('Top')) {
                return 'text-down'
            }
            return 'text-up'
        },
        getArrowPosition() {
            if (this.position.includes('Top')) {
                return 'arrow-down'
            }
            return 'arrow-up'
        },
    },
    methods: {
        hideHint() {
            this.showHint = false
        },
    },
}
</script>

<style scoped>
.tooltip-box {
    position: absolute;
    display: inline-block;
    transform: translateX(5px);
    z-index: 1000;
}

.close-hint {
    position: absolute;
    right: 0;
    padding: 8px;
    cursor: pointer;
}
.hint {
    cursor: pointer;
}
.text {
    font-size: 20px;
    padding: 10px;
    padding-right: 15px;
}

.tooltip {
    color: #333;
    border-radius: 1px;
    width: var(--width);
    right: var(--box-placement);
    display: block;

    position: absolute;
    z-index: 1;
    border: 2px solid #f07f09;
    border-radius: 5px;

    background: #fff;
}

.text-up::after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    right: var(--arrow-placement);
}

.text-down::after {
    content: ' ';
    position: absolute;
    top: 100%;
    right: var(--arrow-placement);
}

.arrow-up {
    position: absolute;
    bottom: 99%;
    right: var(--arrow-placement);
}
.arrow-down {
    position: absolute;
    top: 99%;
    right: var(--arrow-placement);
    transform: rotate(180deg);
}
</style>
