import { GetterTree, ActionTree, MutationTree } from 'vuex'
// This strict = false shouldn't be needed and might not be needed in future versions of Nuxt.  It comes from a true bug within Nuxt with Typescript, whereby it incorrectly warns of state being mutated outside of a mutation, when that is not what's happening. https://github.com/nuxt/nuxt.js/issues/1917
export const strict = false
export const state = () => ({})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {}

export const actions: ActionTree<RootState, RootState> = {}

export const mutations: MutationTree<RootState> = {}
