import { GetterTree, ActionTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { ZoomUiState } from '@/models/zoomUi'
import { RootState } from '@/store'
import { Expense, ExpenseIdentifier } from '@/models/expenses'

const initialState: ZoomUiState = {
    autoplay: true,
    isDragging: false,
    showFooterNavigation: true,
    yearsFromToday: 0,
    inflatedExpenses: [],
    expenses: [],
    redExpenses: [],
    stackToTurnRed: [],
}

export const state = () => ({ ...initialState })

export const getters: GetterTree<ZoomUiState, RootState> = {
    getExpenses: state => [...state.expenses],
    mustBeOrange: (_, __, rootState) => (identifier: ExpenseIdentifier) => {
        return (rootState as any).expenses.guaranteedExpenses.includes(identifier)
    }, // Without rootState as any, TS thinks rootState is an empty object.
    totalValueOfRedExpenses: state =>
        state.inflatedExpenses
            .filter((expense: any) => expense.canAfford === false)
            .reduce((accumulator, expense: any) => accumulator + expense.monthlyCost, 0),
    valueOfExpense: state => (identifier: ExpenseIdentifier) =>
        state.inflatedExpenses.find(expense => expense.identifier === identifier)?.monthlyCost,
    nextExpenseToBeWhite: state => state!.redExpenses[state.redExpenses.length - 1],
}
export const actions: ActionTree<ZoomUiState, RootState> = {
    initializeExpenses({ commit }, expenses) {
        commit(INITIALIZE_EXPENSES, expenses)
    },
    setYearsFromToday({ commit }, yearsFromToday) {
        commit(SET_YEARS_FROM_TODAY, yearsFromToday)
    },
    setExpense({ commit }, expense: Expense) {
        commit(SET_EXPENSE, expense)
    },
    setInflatedExpenses({ commit }, inflatedExpenses) {
        commit(SET_INFLATED_EXPENSES, inflatedExpenses)
    },
    setAutoplay({ commit }, mustPlay) {
        commit(SET_AUTOPLAY, mustPlay)
    },
    setIsDragging({ commit }, isDragging) {
        commit(SET_IS_DRAGGING, isDragging)
    },
    initializeStackToTurnRed({ commit }, queue) {
        commit(SET_RED_QUEUE, queue)
    },
    initializeRedExpenses({ commit }, redExpenses) {
        commit(SET_RED_EXPENSES, redExpenses)
    },
    turnNextExpenseRed({ commit }) {
        commit(TURN_NEXT_EXPENSE_RED)
    },
    turnNextExpenseWhite({ commit }) {
        commit(TURN_NEXT_EXPENSE_WHITE)
    },
    setBudgetSize({ commit }, budget) {
        commit(SET_BUDGET_SIZE, budget)
    },
    showFooterNavigation({ commit }, mustShow) {
        commit(SHOW_FOOTER_NAVIGATION, mustShow)
    },
}

export const INITIALIZE_EXPENSES = 'INITIALIZE_EXPENSES'
export const INITIALIZE_SHORTFALL_EXPENSES = 'INITIALIZE_SHORTFALL_EXPENSES'
export const SET_EXPENSE = 'SET_EXPENSE'
export const SET_YEARS_FROM_TODAY = 'SET_YEARS_FROM_TODAY'
export const SET_AUTOPLAY = 'SET_AUTOPLAY'
export const SET_INFLATED_EXPENSES = 'SET_INFLATED_EXPENSES'
export const SET_IS_DRAGGING = 'SET_IS_DRAGGING'
export const SET_RED_QUEUE = 'SET_RED_QUEUE'
export const TURN_NEXT_EXPENSE_RED = 'TURN_NEXT_EXPENSE_RED'
export const TURN_NEXT_EXPENSE_WHITE = 'TURN_NEXT_EXPENSE_WHITE'
export const SET_RED_EXPENSES = 'SET_RED_EXPENSES'
export const SET_BUDGET_SIZE = 'SET_BUDGET_SIZE'
export const SHOW_FOOTER_NAVIGATION = 'SHOW_FOOTER_NAVIGATION'

export const mutations: MutationTree<ZoomUiState> = {
    [INITIALIZE_EXPENSES](state, expenses) {
        state.expenses = expenses
    },
    [SET_EXPENSE](state, expense: Expense) {
        const index = state.expenses.findIndex(x => x.identifier === expense.identifier)
        if (index === -1) {
            state.expenses.push(expense)
        } else {
            Vue.set(state.expenses, index, expense)
        }
    },
    [SET_YEARS_FROM_TODAY](state, yearsFromToday) {
        state.yearsFromToday = yearsFromToday
    },
    [SET_AUTOPLAY](state, mustPlay) {
        state.autoplay = mustPlay
    },
    [SET_INFLATED_EXPENSES](state, inflatedExpenses) {
        state.inflatedExpenses = inflatedExpenses
    },
    [SET_IS_DRAGGING](state, isDragging) {
        state.isDragging = isDragging
    },
    [SET_RED_QUEUE](state, queue) {
        state.stackToTurnRed = queue.filter((identifier: ExpenseIdentifier) =>
            state.expenses.map((expense: Expense) => expense.identifier).includes(identifier),
        )
    },
    [TURN_NEXT_EXPENSE_RED](state) {
        if (!state.stackToTurnRed.length) {
            return
        }
        const nextExpense: ExpenseIdentifier | undefined = state.stackToTurnRed.pop()
        if (nextExpense) {
            state.redExpenses.push(nextExpense)
        }
    },
    [TURN_NEXT_EXPENSE_WHITE](state) {
        if (!state.redExpenses.length) {
            return
        }
        const nextExpense: ExpenseIdentifier | undefined = state!.redExpenses!.pop()
        if (nextExpense) {
            state.stackToTurnRed.push(nextExpense)
        }
    },
    [SET_RED_EXPENSES](state, redExpenses) {
        state.redExpenses = redExpenses
    },
    [SET_BUDGET_SIZE](state, budget) {
        const index = state.expenses.findIndex(x => x.identifier === budget.identifier)
        if (index !== -1) {
            Vue.set(state.expenses[index], 'budgetSize', budget.budgetSize)
        }
    },
    [SHOW_FOOTER_NAVIGATION](state, mustShow) {
        state.showFooterNavigation = mustShow
    },
}
