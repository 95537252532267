export enum ExpenseIdentifier {
    // using FREE letters here, because sometimes it is used to reference a key/identifier in an object.
    Accommodation = 'home',
    Groceries = 'groceries',
    Transport = 'transport',
    Communication = 'connectivity',
    Medical = 'medical',
    Insurance = 'policiesInsurance',
    DebtRepayment = 'debt',
    EntertainmentHobbies = 'hobbies',
    Other = 'other',
}
export const GtmExpenseIdentifierMap = new Map([
    [ExpenseIdentifier.Accommodation, 'accommodation'],
    [ExpenseIdentifier.Groceries, 'groceries'],
    [ExpenseIdentifier.Transport, 'transport'],
    [ExpenseIdentifier.Communication, 'communication'],
    [ExpenseIdentifier.Medical, 'medical'],
    [ExpenseIdentifier.Insurance, 'insurance'],
    [ExpenseIdentifier.DebtRepayment, 'debtRepayments'],
    [ExpenseIdentifier.EntertainmentHobbies, 'entertainment'],
    [ExpenseIdentifier.Other, 'other'],
])
export enum BudgetSize {
    FREE = 'FREE',
    BUDGET = 'BUDGET',
    MID = 'MID',
    EXPENSIVE = 'EXPENSIVE',
}

export type BudgetSizeFrames = {
    [BudgetSize.FREE]: number
    [BudgetSize.BUDGET]: number
    [BudgetSize.MID]: number
    [BudgetSize.EXPENSIVE]?: number
}

export type PositionInFrame = {
    left: string
    top: string
    width?: string
    height?: string
    zIndex?: number
}

export type ZoomPosition = {
    leftRight: number
    upDown: number
    zoomPercent: number
}

export type ExpenseUiConfiguration = {
    identifier: string
    previewIcon?: string
    label: string
    iconWidth?: string
    showAnimation?: boolean
    pathToAnimation: string
    budgetSizeFrameNumbers: Map<BudgetSize, number>
    budgetSizeImages: Map<BudgetSize, string>
    budgetSizeGhostImages: Map<BudgetSize, string>
    positionOnLandscape: PositionInFrame
    positionOfIsland: null | PositionInFrame
    zoomPosition: ZoomPosition
    costBubblePosition: PositionInFrame
    hintText: string
}

export type Expense = {
    identifier: string
    monthlyCost: number | null
    originalMonthlyCost?: number | null
    budgetSize: BudgetSize
    originalBudgetSize?: BudgetSize
    willThisBecomeLess: boolean
    lessPaymentYear: number | null
    lessPaymentMonthlyCost: number | null
}

export type ExpensesState = {
    isAddingExpenses: boolean
    items: Expense[]
    priorities: ExpenseIdentifier[]
    guaranteedExpenses: ExpenseIdentifier[]
}
