import { render, staticRenderFns } from "./BaseBubbleCard.vue?vue&type=template&id=58b92585&scoped=true&"
import script from "./BaseBubbleCard.vue?vue&type=script&lang=js&"
export * from "./BaseBubbleCard.vue?vue&type=script&lang=js&"
import style0 from "./BaseBubbleCard.vue?vue&type=style&index=0&id=58b92585&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b92585",
  null
  
)

export default component.exports