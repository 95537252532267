import Vue from 'vue'
import numeral from 'numeral'

Vue.filter('currency', function(value) {
    return (
        'R' +
        numeral(value)
            .format('0,0')
            .replace(/,/g, ' ')
    )
})

Vue.filter('numeral', function(value) {
    return numeral(value).value()
})
