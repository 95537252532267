<template>
    <div class="container">
        <div class="home">
            <nuxt-link to="/">
                <img src="~/assets/breadcrumb/button_home.svg" width="100px" alt="Home" />
            </nuxt-link>
            <div class="menu-title">HOME</div>
        </div>
        <ul class="breadcrumb">
            <template v-if="previousRoute">
                <nuxt-link :to="{ path: previousRoute.link }">
                    <li class="previous">{{ previousRoute.label }}</li>
                </nuxt-link>
                <li>
                    <img width="13px" src="~/assets/breadcrumb/breadcrumb_arrow_dark.svg" />
                </li>
            </template>

            <li v-if="currentRoute" class="current">{{ currentRoute.label }}</li>

            <template v-if="nextRoutes.length !== 0">
                <li>
                    <img width="13px" src="~/assets/breadcrumb/breadcrumb_arrow_orange.svg" />
                </li>
                <li v-for="(nextRoute, i) in nextRoutes.slice(0, 2)" :key="i" :class="{ inactive: nextRoute.locked }">
                    <nuxt-link v-if="!nextRoute.locked" class="active" :to="{ path: nextRoute.link }">{{
                        nextRoute.label
                    }}</nuxt-link>
                    <template v-else>{{ nextRoute.label }}</template>
                </li>
            </template>
        </ul>
        <!-- <div style="float: right">
            <div class="connect">
                <div style="cursor: pointer" @click="goToAdvisor">
                    <img src="~/assets/breadcrumb/button_connect.svg" height="68px" alt="Connect" />
                </div>
                <div class="menu-title">
                    CONNECT
                </div>
            </div>
            <div class="learn">
                <div><img src="~/assets/breadcrumb/button_learn.svg" height="68px" alt="Learn" /></div>
                <div class="menu-title">
                    LEARN
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        previousRoute: {
            type: Object,
            default: null,
        },
        currentRoute: {
            type: Object,
            default: null,
        },
        nextRoutes: {
            type: Array,
            required: true,
        },
    },
    methods: {
        goToAdvisor() {
            localStorage.setItem('advice-path', this.$route.path)
            this.$router.push('/get-advice/submit-to-advisor')
        },
    },
}
</script>

<style scoped>
.container {
    list-style: none;
    display: inline-block;
    padding: 1%;
    width: 100vw;
    position: absolute;
    z-index: 1;
}
.menu-title {
    text-align: center;
    margin-right: -11px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}
.home {
    display: inline-block;
}
.connect {
    display: inline-block;
    vertical-align: top;
    /* padding-left: 10%; */
    right: 0;
}
.learn {
    display: inline-block;
    vertical-align: top;
    margin-left: -9px;
}
ul.breadcrumb {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    padding: 10px 0 10px 100px;
    list-style: none;
}
ul.breadcrumb li {
    display: inline;
    font-size: 18px;
    padding-left: 20px;
    vertical-align: top;
}
@media (max-width: 1130px) {
    ul.breadcrumb {
        padding: 10px 0 10px 40px;
    }
    ul.breadcrumb li {
        font-size: 14px;
    }
}

.previous {
    font-weight: 700;
    color: #333;
}
.previous:hover {
    color: #f07f09;
    cursor: pointer;
}
.current {
    font-weight: 700;
    color: #f07f09;
}
ul.breadcrumb li + li:before {
    padding: 8px;
}
a {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
.active {
    color: #333;
    font-weight: 700;
    cursor: pointer;
}
li.inactive {
    color: #a2a2a2;
}
</style>
