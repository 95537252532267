<template>
    <div class="base-currency-input">
        <vue-autonumeric
            :style="cssVars"
            :class="{ 'no-border': noBorder }"
            :value="value"
            :options="options"
            v-bind="$attrs"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue'
export default {
    layout: 'noBreadCrumb',
    components: {
        VueAutonumeric,
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        inputSize: {
            type: Number,
            default: 222,
        },
        options: {
            type: Object,
            default() {
                return {
                    allowDecimalPadding: 'floats',
                    currencySymbol: 'R ',
                    digitGroupSeparator: ' ',
                    minimumValue: '0',
                    emptyInputBehavior: 'null',
                }
            },
        },
    },
    computed: {
        cssVars() {
            return {
                '--input-width': this.inputSize + 'px',
            }
        },
    },
    methods: {
        onChange(value) {
            this.$emit('input', value)
        },
    },
}
</script>

<style>
/**Exposed this class to allow @media queries to have an impact on width.
gave it greater specificity to avoid potential nameclashes */
.base-currency-input input {
    font-size: 20px;
    font-weight: lighter;
    width: var(--input-width);
}
.base-currency-input ::placeholder {
    font-size: 18px;
    color: #a2a2a2;
}
</style>
<style scoped>
/* input {
    font-size: 18px;
    width: var(--input-width);
    font-family: 'Source Sans Pro';
} */
/* ::placeholder {
    font-size: 18px;
    font-family: 'Source Sans Pro';
    color: #a2a2a2;
} */
.no-border {
    border-bottom: 1px solid white;
}
@media screen and (max-width: 1024px) and (max-height: 768px) {
    .base-currency-input input {
        font-size: 17px;
    }

    .base-currency-input ::placeholder {
        font-size: 15px;
    }
}
</style>
