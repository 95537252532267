import { BudgetSize, ExpenseIdentifier, ExpenseUiConfiguration } from '@/models/expenses'
import { Section, SectionUIConfig } from '@/models/sections'
import {
    FemaleHairSelectionIdentifier,
    FemaleOutfitSelectionIdentifier,
    MaleOutfitSelectionIdentifier,
    MaleHairSelectionIdentifier,
    avatarSelection,
} from '@/models/user'

export const _femaleHairSelections: avatarSelection[] = [
    {
        identifier: FemaleHairSelectionIdentifier.Bob,
        pathToThumbnail: 'characters/female/buttons/hair_bob.png',
    },
    {
        identifier: FemaleHairSelectionIdentifier.Scarf,
        pathToThumbnail: 'characters/female/buttons/hair_headscarf.png',
    },
    {
        identifier: FemaleHairSelectionIdentifier.Bun,
        pathToThumbnail: 'characters/female/buttons/hair_bun.png',
    },
    {
        identifier: FemaleHairSelectionIdentifier.Curly,
        pathToThumbnail: 'characters/female/buttons/hair_curly.png',
    },
    {
        identifier: FemaleHairSelectionIdentifier.Hijab,
        pathToThumbnail: 'characters/female/buttons/hair_hijab.png',
    },
    {
        identifier: FemaleHairSelectionIdentifier.LongStraight,
        pathToThumbnail: 'characters/female/buttons/hair_long_straight.png',
    },
    {
        identifier: FemaleHairSelectionIdentifier.ShortStraight,
        pathToThumbnail: 'characters/female/buttons/hair_short_straight.png',
    },
    {
        identifier: FemaleHairSelectionIdentifier.SunHat,
        pathToThumbnail: 'characters/female/buttons/hair_sun_hat.png',
    },
]

export const _maleHairSelections: avatarSelection[] = [
    {
        identifier: MaleHairSelectionIdentifier.Cap,
        pathToThumbnail: 'characters/male/buttons/hair_cap.png',
    },
    {
        identifier: MaleHairSelectionIdentifier.CleanCut,
        pathToThumbnail: 'characters/male/buttons/hair_clean_cut1.png',
    },
    {
        identifier: MaleHairSelectionIdentifier.Mustache,
        pathToThumbnail: 'characters/male/buttons/hair_clean_cut2.png',
    },
    {
        identifier: MaleHairSelectionIdentifier.CombBack,
        pathToThumbnail: 'characters/male/buttons/hair_comb_back.png',
    },
    {
        identifier: MaleHairSelectionIdentifier.Cropped,
        pathToThumbnail: 'characters/male/buttons/hair_stubble.png',
    },
    {
        identifier: MaleHairSelectionIdentifier.Curly,
        pathToThumbnail: 'characters/male/buttons/hair_curly.png',
    },
    {
        identifier: MaleHairSelectionIdentifier.Fedora,
        pathToThumbnail: 'characters/male/buttons/hair_fedora.png',
    },
    {
        identifier: MaleHairSelectionIdentifier.FlatCap,
        pathToThumbnail: 'characters/male/buttons/hair_flat_cap.png',
    },
]

export const _femaleOutfitSelections: avatarSelection[] = [
    {
        identifier: FemaleOutfitSelectionIdentifier.Cardigan,
        pathToThumbnail: 'characters/female/buttons/outfit_cardigan.png',
    },
    {
        identifier: FemaleOutfitSelectionIdentifier.Dress,
        pathToThumbnail: 'characters/female/buttons/outfit_dress.png',
    },
    {
        identifier: FemaleOutfitSelectionIdentifier.Knit,
        pathToThumbnail: 'characters/female/buttons/outfit_knit.png',
    },
    {
        identifier: FemaleOutfitSelectionIdentifier.Loose,
        pathToThumbnail: 'characters/female/buttons/outfit_loose.png',
    },
    {
        identifier: FemaleOutfitSelectionIdentifier.Poncho,
        pathToThumbnail: 'characters/female/buttons/outfit_poncho.png',
    },
    {
        identifier: FemaleOutfitSelectionIdentifier.Puffer,
        pathToThumbnail: 'characters/female/buttons/outfit_puffer.png',
    },
    {
        identifier: FemaleOutfitSelectionIdentifier.Shweshwe1,
        pathToThumbnail: 'characters/female/buttons/outfit_shweshwe1.png',
    },
    {
        identifier: FemaleOutfitSelectionIdentifier.Shweshwe2,
        pathToThumbnail: 'characters/female/buttons/outfit_shweshwe2.png',
    },
]

export const _maleOutfitSelections: avatarSelection[] = [
    {
        identifier: MaleOutfitSelectionIdentifier.Africa,
        pathToThumbnail: 'characters/male/buttons/outfit_africa.png',
    },
    {
        identifier: MaleOutfitSelectionIdentifier.Flannel,
        pathToThumbnail: 'characters/male/buttons/outfit_flannel.png',
    },
    {
        identifier: MaleOutfitSelectionIdentifier.Golf,
        pathToThumbnail: 'characters/male/buttons/outfit_golf.png',
    },
    {
        identifier: MaleOutfitSelectionIdentifier.Khuta,
        pathToThumbnail: 'characters/male/buttons/outfit_khuta.png',
    },
    {
        identifier: MaleOutfitSelectionIdentifier.LongSleeve,
        pathToThumbnail: 'characters/male/buttons/outfit_long_sleeve.png',
    },
    {
        identifier: MaleOutfitSelectionIdentifier.ShortSleeve,
        pathToThumbnail: 'characters/male/buttons/outfit_short_sleeve.png',
    },
    {
        identifier: MaleOutfitSelectionIdentifier.VeldFocus,
        pathToThumbnail: 'characters/male/buttons/outfit_veldfocus.png',
    },
]

// Each image has a unique height as per the Style Guide.
export const _sectionCompletedImages: SectionUIConfig = {
    [Section.ABOUT_ME]: 'main-menu/step_1.png',
    [Section.MY_RETIREMENT_SAVINGS]: 'main-menu/step_2.png',
    [Section.GUARANTEED_INCOME_OPTION]: 'main-menu/step_3.png',
    [Section.EXPENSES]: 'main-menu/step_4.png',
    [Section.WAYS_TO_A_BETTER_RETIREMENT]: 'main-menu/step_5.png',
    [Section.FLEXIBLE_INCOME_OPTION]: 'main-menu/step_6.png',
    [Section.FULL_RETIREMENT_PICTURE]: 'main-menu/step_7.png',
    [Section.GET_ADVICE]: 'main-menu/step_8.png',
}

export const _sliderCardTextStep4Landscape = [
    { identifier: ExpenseIdentifier.Accommodation, text: 'What does your current place to live look like?' },
    { identifier: ExpenseIdentifier.Groceries, text: 'What does your current way of shopping look like?' },
    {
        identifier: ExpenseIdentifier.Transport,
        text: 'What does your current way of getting around look like?',
    },
    {
        identifier: ExpenseIdentifier.Communication,
        text: 'What does your current way of connecting with others look like?',
    },
    { identifier: ExpenseIdentifier.Medical, text: 'What do your current healthcare costs look like?' },
    { identifier: ExpenseIdentifier.Insurance, text: 'What does your current insurance cover look like?' },
    { identifier: ExpenseIdentifier.DebtRepayment, text: 'What do your current debt payments look like?' },
    {
        identifier: ExpenseIdentifier.EntertainmentHobbies,
        text: 'What do your current hobbies and things you enjoy look like?',
    },
    {
        identifier: ExpenseIdentifier.Other,
        text: 'What do your "other" costs currently look like?',
    },
]

export const _sliderCardTextStep5Landscape = [
    {
        identifier: ExpenseIdentifier.Accommodation,
        text: `Reduce this expense by downsizing, paying off your bond, or applying for pensioner's rates and taxes discount.`,
    },
    {
        identifier: ExpenseIdentifier.Groceries,
        text:
            'Buying groceries in bulk is one way to reduce this expense. Cutting down on sugary treats and convenience foods like takeaways, and restaurant meals also helps.',
    },
    {
        identifier: ExpenseIdentifier.Transport,
        text: `This expense will be reduced in your retirement years, as you'll no longer be spending money on your commute to work, and it can be reduced further by using public transportation.`,
    },
    {
        identifier: ExpenseIdentifier.Communication,
        text: `Since you've retired, you won't need to make any work-related calls, so you'll save money on communication costs since your personal communication devices won't be used for work-related calls or data usage.`,
    },
    {
        identifier: ExpenseIdentifier.Medical,
        text:
            'Using free clinics, cheaper pharmacy services, and network-only medical aids or hospital plans can help reduce this expense.',
    },
    {
        identifier: ExpenseIdentifier.Insurance,
        text: 'Shop around for other providers or negotiate with your insurance company to get a lower premium.',
    },
    {
        identifier: ExpenseIdentifier.DebtRepayment,
        text: 'Pay off your outstanding debts and avoid taking on new debts.',
    },
    {
        identifier: ExpenseIdentifier.EntertainmentHobbies,
        text: `Ask for a pensioner's discount.`,
    },
    {
        identifier: ExpenseIdentifier.Other,
        text: `Ask for a pensioner's discount.`,
    },
]

export const _sliderCardTextStep7Landscape = [
    { identifier: ExpenseIdentifier.Accommodation, text: 'What will your place to live look like during retirement?' },
    { identifier: ExpenseIdentifier.Groceries, text: 'What will your way of shopping look like during retirement?' },
    {
        identifier: ExpenseIdentifier.Transport,
        text: 'What will your way of getting around look like during retirement?',
    },
    {
        identifier: ExpenseIdentifier.Communication,
        text: 'What will your way of connecting with others look like during retirement?',
    },
    { identifier: ExpenseIdentifier.Medical, text: 'What will your healthcare costs look like during retirement?' },
    { identifier: ExpenseIdentifier.Insurance, text: 'What will your insurance cover look like during retirement?' },
    { identifier: ExpenseIdentifier.DebtRepayment, text: 'What will your debt payments look like during retirement?' },
    {
        identifier: ExpenseIdentifier.EntertainmentHobbies,
        text: 'What will your hobbies and things you enjoy look like during retirement?',
    },
    {
        identifier: ExpenseIdentifier.Other,
        text: 'What will your "other" costs look like during retirement?',
    },
]

export const _expenseUiConfigurations: ExpenseUiConfiguration[] = [
    {
        identifier: ExpenseIdentifier.Accommodation,
        previewIcon: 'expenses/icons/1_accomodation_deselected@4x.png',
        label: 'Place to live',
        iconWidth: '142px',
        pathToAnimation: 'animations/accommodation/MRP_RTM_Accomodation_4_stages_v7.json',
        budgetSizeFrameNumbers: new Map<BudgetSize, number>([
            [BudgetSize.FREE, 0],
            [BudgetSize.BUDGET, 102],
            [BudgetSize.MID, 200],
            [BudgetSize.EXPENSIVE, 277],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/accommodation/free.png'],
            [BudgetSize.BUDGET, 'expenses/accommodation/budget.png'],
            [BudgetSize.MID, 'expenses/accommodation/mid.png'],
            [BudgetSize.EXPENSIVE, 'expenses/accommodation/expensive.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/accommodation/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/accommodation/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/accommodation/midGhost.png'],
            [BudgetSize.EXPENSIVE, 'expenses/accommodation/expensiveGhost.png'],
        ]),
        positionOnLandscape: {
            left: '38.3%',
            top: '25%',
            width: '28.3%',
            zIndex: 3,
        },
        positionOfIsland: null,
        zoomPosition: {
            leftRight: 30,
            upDown: 70,
            zoomPercent: 180,
        },
        costBubblePosition: {
            left: '50.3%',
            top: '40%',
        },
        hintText: 'Add up all your regular household expenses e.g. rent, electricity, water, rates, levies or garden.',
    },
    {
        identifier: ExpenseIdentifier.Groceries,
        previewIcon: 'expenses/icons/2_groceries_deselected@4x.png',
        label: 'Shopping',
        iconWidth: '90px',
        pathToAnimation: 'animations/groceries/MRP_RTM_Groceries_3_stages_v5.json',
        budgetSizeFrameNumbers: new Map<BudgetSize, number>([
            [BudgetSize.FREE, 20],
            [BudgetSize.BUDGET, 83],
            [BudgetSize.MID, 158],
            [BudgetSize.EXPENSIVE, 243],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/groceries/free.png'],
            [BudgetSize.BUDGET, 'expenses/groceries/budget.png'],
            [BudgetSize.MID, 'expenses/groceries/mid.png'],
            [BudgetSize.EXPENSIVE, 'expenses/groceries/expensive.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/groceries/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/groceries/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/groceries/midGhost.png'],
            [BudgetSize.EXPENSIVE, 'expenses/groceries/expensiveGhost.png'],
        ]),
        positionOnLandscape: {
            left: '12.5%',
            top: '35.5%',
            width: '52.4%',
            zIndex: 4,
        },
        positionOfIsland: {
            left: '25.5%',
            top: '86.5%',
            width: '21.3%',
            height: '11%',
        },
        zoomPosition: {
            leftRight: 50,
            upDown: 60,
            zoomPercent: 120,
        },
        costBubblePosition: {
            left: '40.5%',
            top: '85%',
        },
        hintText: 'Add up the cost of all your groceries e.g. food, cleaning products or toiletries.',
    },
    {
        identifier: ExpenseIdentifier.Transport,
        previewIcon: 'expenses/icons/3_transport_deselected@4x.png',
        label: 'Getting around',
        iconWidth: '160px',
        pathToAnimation: 'animations/transportation/MRP_RTM_Transport_3_stages_v3.json',
        budgetSizeFrameNumbers: new Map([
            [BudgetSize.FREE, 44],
            [BudgetSize.BUDGET, 108],
            [BudgetSize.MID, 178],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/transport/free.png'],
            [BudgetSize.BUDGET, 'expenses/transport/budget.png'],
            [BudgetSize.MID, 'expenses/transport/mid.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/transport/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/transport/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/transport/midGhost.png'],
        ]),
        positionOnLandscape: {
            left: '58.7%',
            top: '38.3%',
            width: '17.8%',
            zIndex: 2,
        },
        positionOfIsland: null,
        zoomPosition: {
            leftRight: -30,
            upDown: 76,
            zoomPercent: 350,
        },
        costBubblePosition: {
            left: '68.7%',
            top: '40.3%',
        },
        hintText: 'Add up the cost of getting around e.g. public transport fares, uber, fuel or parking.',
    },
    {
        identifier: ExpenseIdentifier.Communication,
        previewIcon: 'expenses/icons/4_communication_deselected@4x.png',
        label: 'Being connected',
        iconWidth: '120px',
        pathToAnimation: 'animations/communication/MRP_RTM_Communication_3_stages_v6.json',
        budgetSizeFrameNumbers: new Map([
            [BudgetSize.FREE, 0],
            [BudgetSize.BUDGET, 67],
            [BudgetSize.MID, 158],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/communication/free.png'],
            [BudgetSize.BUDGET, 'expenses/communication/budget.png'],
            [BudgetSize.MID, 'expenses/communication/mid.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/communication/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/communication/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/communication/midGhost.png'],
        ]),
        positionOnLandscape: {
            left: '50%',
            top: '53.5%',
            width: '32%',
            zIndex: 3,
        },
        positionOfIsland: {
            left: '52.9%',
            top: '82%',
            width: '26.3%',
            height: '13%',
        },
        zoomPosition: {
            leftRight: 5,
            upDown: 50,
            zoomPercent: 165,
        },
        costBubblePosition: {
            left: '65%',
            top: '63.5%',
        },
        hintText: 'Add up the cost for communication e.g. phone data, computer, internet subscription or airtime.',
    },
    {
        identifier: ExpenseIdentifier.Medical,
        previewIcon: 'expenses/icons/5_medical_deselected@4x.png',
        label: 'Healthcare',
        iconWidth: '90px',
        pathToAnimation: 'animations/medical/MRP_RTM_Medical_3_stages_v8.json',
        budgetSizeFrameNumbers: new Map([
            [BudgetSize.FREE, 0],
            [BudgetSize.BUDGET, 130],
            [BudgetSize.MID, 298],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/medical/free.png'],
            [BudgetSize.BUDGET, 'expenses/medical/budget.png'],
            [BudgetSize.MID, 'expenses/medical/mid.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/medical/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/medical/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/medical/midGhost.png'],
        ]),
        positionOnLandscape: {
            left: '-10%',
            top: '-53.2%',
            width: '108.4%',
            zIndex: 1,
        },
        positionOfIsland: null,
        zoomPosition: {
            leftRight: 42,
            upDown: 82,
            zoomPercent: 120,
        },
        costBubblePosition: {
            left: '34.3%',
            top: '19.9%',
        },
        hintText:
            'Add up the cost of all your healthcare expenses e.g. medical aid, doctor visits, spectacles or medication.',
    },
    // I've given Insurance and Debt their own islands in identical positions, so that there's at least an island regardless which expense is visible.
    {
        identifier: ExpenseIdentifier.Insurance,
        previewIcon: 'expenses/icons/6_insurance_deselected@4x.png',
        label: 'Insurance cover',
        iconWidth: '75px',
        pathToAnimation: 'animations/insurance/MRP_RTM_Insurance_3_stages_v4.json',
        budgetSizeFrameNumbers: new Map([
            [BudgetSize.FREE, 0],
            [BudgetSize.BUDGET, 60],
            [BudgetSize.MID, 146],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/insurance/free.png'],
            [BudgetSize.BUDGET, 'expenses/insurance/budget.png'],
            [BudgetSize.MID, 'expenses/insurance/mid.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/insurance/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/insurance/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/insurance/midGhost.png'],
        ]),
        positionOnLandscape: {
            left: '1.1%',
            top: '51.5%',
            width: '15%',
            zIndex: 3,
        },
        positionOfIsland: {
            left: '3.35%',
            top: '74.5%',
            width: '25.5%',
            height: '12.5%',
        },
        zoomPosition: {
            leftRight: 122,
            upDown: 50,
            zoomPercent: 200,
        },
        costBubblePosition: {
            left: '1.1%',
            top: '76.5%',
        },
        hintText: 'Add up the cost of all your insurances e.g. car, home, disability or life cover.',
    },
    {
        identifier: ExpenseIdentifier.DebtRepayment,
        previewIcon: 'expenses/icons/7_debt_repayments_deselected@4x.png',
        label: 'Debt payments',
        iconWidth: '121px',
        pathToAnimation: 'animations/debt/MRP_RTM_Debt_3_stages_v3.json',
        budgetSizeFrameNumbers: new Map([
            [BudgetSize.FREE, 0],
            [BudgetSize.BUDGET, 24],
            [BudgetSize.MID, 48],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/debt/free.png'],
            [BudgetSize.BUDGET, 'expenses/debt/budget.png'],
            [BudgetSize.MID, 'expenses/debt/mid.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/debt/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/debt/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/debt/midGhost.png'],
        ]),
        positionOnLandscape: {
            left: '10.17%',
            top: '55.7%',
            width: '20.7%',
            zIndex: 3,
        },
        positionOfIsland: {
            left: '3.35%',
            top: '74.5%',
            width: '25%',
            height: '12.5%',
        },
        zoomPosition: {
            leftRight: 80,
            upDown: 50,
            zoomPercent: 160,
        },
        costBubblePosition: {
            left: '20.17%',
            top: '78.7%',
        },
        hintText: 'Add up everything you owe e.g. home loan, car, credit cards or shop accounts.',
    },
    {
        identifier: ExpenseIdentifier.EntertainmentHobbies,
        previewIcon: 'expenses/icons/8_entertainment_and_hobbies_deselected@4x.png',
        label: 'Hobbies and activities',
        iconWidth: '65px',
        pathToAnimation: 'animations/hobbies/MRP_RTM_Hobbies_4_stages_v5.json',
        budgetSizeFrameNumbers: new Map([
            [BudgetSize.FREE, 0],
            [BudgetSize.BUDGET, 78],
            [BudgetSize.MID, 147],
            [BudgetSize.EXPENSIVE, 231],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/hobbies/free.png'],
            [BudgetSize.BUDGET, 'expenses/hobbies/budget.png'],
            [BudgetSize.MID, 'expenses/hobbies/mid.png'],
            [BudgetSize.EXPENSIVE, 'expenses/hobbies/expensive.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/hobbies/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/hobbies/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/hobbies/midGhost.png'],
            [BudgetSize.EXPENSIVE, 'expenses/hobbies/expensiveGhost.png'],
        ]),
        positionOnLandscape: {
            left: '70.8%',
            top: '4.3%',
            width: '30.3%',
            zIndex: 3,
        },
        positionOfIsland: {
            left: '75.9%',
            top: '73.2%',
            width: '20.2%',
            height: '12.7%',
        },
        zoomPosition: {
            leftRight: -10,
            upDown: 67,
            zoomPercent: 130,
        },
        costBubblePosition: {
            left: '78.6%',
            top: '70%',
        },
        hintText:
            'Add up the cost of your e.g. entertainment TV, subscriptions, books, gifts, hobbies, holidays and travel, gardening or restaurants.',
    },
    {
        identifier: ExpenseIdentifier.Other,
        previewIcon: 'expenses/icons/9_other_deselected@4x.png',
        label: 'Other costs',
        iconWidth: '160px',
        pathToAnimation: 'animations/other/MRP_RTM_Other_3_stages_v5.json',
        budgetSizeFrameNumbers: new Map([
            [BudgetSize.FREE, 0],
            [BudgetSize.BUDGET, 113],
            [BudgetSize.MID, 225],
        ]),
        budgetSizeImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/other/free.png'],
            [BudgetSize.BUDGET, 'expenses/other/budget.png'],
            [BudgetSize.MID, 'expenses/other/mid.png'],
        ]),
        budgetSizeGhostImages: new Map<BudgetSize, string>([
            [BudgetSize.FREE, 'expenses/other/freeGhost.png'],
            [BudgetSize.BUDGET, 'expenses/other/budgetGhost.png'],
            [BudgetSize.MID, 'expenses/other/midGhost.png'],
        ]),
        positionOnLandscape: {
            left: '20.3%',
            top: '24.7%',
            width: '24%',
            zIndex: 2,
        },
        positionOfIsland: null,
        zoomPosition: {
            leftRight: 75,
            upDown: 85,
            zoomPercent: 250,
        },
        costBubblePosition: {
            left: '23%',
            top: '40%',
        },
        hintText:
            'Add up all your miscellaneous costs e.g. fitness, clothing, family responsibilities like maintenance, childcare, pets and education-related expenses like fees and equipment.',
    },
]
