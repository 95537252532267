// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/buttons/button_previous.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/buttons/button_next.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/buttons/button_orange_plus.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/buttons/button_orange_minus.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".base-button{display:inline-flex;align-items:center;justify-content:center;height:55px;padding:0 40px;background:transparent;border:none;border-radius:27px;text-align:center;font-weight:600;font-size:18px;white-space:nowrap;transition:all .2s linear;min-width:110px;margin:3px 8px}.base-button:hover{transform:scale(1.02);box-shadow:0 7px 17px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);cursor:pointer}.base-button:active{transform:scale(1);box-shadow:none}.base-button:focus{outline:0}.base-button.black-square{background:#333;color:#fff;border-radius:2px}.base-button.black-rounded{background:#333;color:#fff;border-radius:27px}.base-button.double-wide-black-rounded{background:#333;color:#fff;border-radius:35px;height:70px;min-width:270px;font-size:24px}.base-button.white-rounded{background:#fff;color:#a2a2a2;border-radius:27px;border:2px solid #a2a2a2}.base-button.white-square{background:#fff;color:#000;border-radius:2px;border:1px solid #161616}.base-button.block{width:100%;margin:6px 0}.base-button.navigator-next,.base-button.navigator-previous{position:relative;color:#fff;border-radius:2px;margin:0;font-size:12px;width:189px;height:77px}.base-button.navigator-previous{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.base-button.navigator-next{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}.base-button.orange-minus,.base-button.orange-plus{height:44px;width:44px;min-width:0;margin:0;border-radius:50%;padding:0}.base-button.orange-plus{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat}.base-button.orange-minus{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat}@media screen and (max-width:1024px) and (max-height:768px){.base-button{font-size:15px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
