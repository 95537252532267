


















import Vue from 'vue'
import VueSelect from 'vue-select'

import 'vue-select/dist/vue-select.css'

export default Vue.extend({
    components: {
        VueSelect,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        width: {
            default: null,
            type: String,
        },
        options: {
            required: true,
            type: Array,
        },
        value: {
            type: [String, Number],
            default: null,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        handleInput(selection: Number | String) {
            this.$emit('input', selection)
        },
    },
})
