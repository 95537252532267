export enum Section {
    // replace underscore for hyphen as to keep consistent with page name
    ABOUT_ME = 'ABOUT-ME',
    MY_RETIREMENT_SAVINGS = 'MY-RETIREMENT-SAVINGS',
    GUARANTEED_INCOME_OPTION = 'GUARANTEED-INCOME-OPTION',
    EXPENSES = 'EXPENSES',
    WAYS_TO_A_BETTER_RETIREMENT = 'WAYS-TO-A-BETTER-RETIREMENT',
    FLEXIBLE_INCOME_OPTION = 'FLEXIBLE-INCOME-OPTION',
    FULL_RETIREMENT_PICTURE = 'FULL-RETIREMENT-PICTURE',
    GET_ADVICE = 'GET-ADVICE',
}
// Naming things: first had this as SectionState, but that's just too close to SectionsState below.
export enum SectionStatus {
    LOCKED = 'LOCKED',
    UNLOCKING = 'UNLOCKING',
    UNLOCKED = 'UNLOCKED',
    COMPLETED = 'COMPLETED',
}

type SubSection = {
    label: string
    link: string
    locked: boolean
}
// Please tell us more about this way of making types. Not seeing an obvious example online yet <-- Googling like "Typescript types with pipes", goes to very different stuff.
type Sections =
    | Section.ABOUT_ME
    | Section.MY_RETIREMENT_SAVINGS
    | Section.GUARANTEED_INCOME_OPTION
    | Section.EXPENSES
    | Section.WAYS_TO_A_BETTER_RETIREMENT
    | Section.FLEXIBLE_INCOME_OPTION
    | Section.FULL_RETIREMENT_PICTURE
    | Section.GET_ADVICE

type LockableSection = {
    status: SectionStatus
    subSections: SubSection[]
}

export type SectionUIConfig = {
    [section in Sections]: string
}

export type SectionsState = {
    [section in Sections]: LockableSection
}
