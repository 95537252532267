export enum SavingsUnit {
    PERCENTAGE = 'PERCENTAGE',
    ABSOLUTE = 'ABSOLUTE',
}

type SavingsContribution = {
    unit: SavingsUnit | null
    amount: number | null
}

export type SavingsInfo = {
    afCurrentSavings: number | null
    afMonthlyContribution: SavingsContribution | null
    otherFundExpensesPercentage: number | null
    otherCurrentSavings: number | null
    otherMonthlyContribution: number | null
    predictedTotalSavings: number | null
}

type JobIncome = {
    jobInitialIncome: number | null
    ageDone: number | null
}
type OtherInvestmentsIncome = {
    cashAvailable: number | null
    propertyAvailable: number | null
    sharesAvailable: number | null
}
type BetterWaysOption = {
    jobIncome: JobIncome | null
    otherInvestmentsIncome: OtherInvestmentsIncome | null
}
type FlexibleIncomeOption = {
    flexibleOptionRange: number[]
    flexibleIncome: number | null
    retirementPolicy: number | null
    guarantedIncomesChosen: number[]
}

export type IncomeState = {
    monthlyIncome: number | null
    salaryInfo: {
        grossSalary: number | null
        fundSalaryPercentage: number | null
    }
    fundSalaryPercentage: number | null
    guaranteedIncomes: number[]
    flexibleIncomes: number[]
    savingsInfo: SavingsInfo
    betterWaysOption: BetterWaysOption
    flexibleIncomeOption: FlexibleIncomeOption
}
