<template>
    <div v-if="loading" class="loading-page">
        <div class="loading"></div>
    </div>
</template>
<script>
export default {
    data: () => ({
        loading: false,
    }),
    methods: {
        start() {
            this.loading = true
        },
        finish() {
            this.loading = false
        },
    },
}
</script>
<style scoped>
.loading-page {
    background-color: grey;
    opacity: 0.8;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    padding: 1rem;
    text-align: center;
    font-size: 3rem;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border: 6px solid #f07f09;
    border-radius: 50%;
    border-top-color: black;
    animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
</style>
