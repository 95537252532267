<template>
    <div class="wrapper" :style="cssVars">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 476,
        },
        height: {
            type: Number,
            default: 118,
        },
    },
    computed: {
        borderRadius() {
            return Math.round(this.height / 2)
        },
        cssVars() {
            return {
                '--width': this.width.toString() + 'px',
                '--height': this.height.toString() + 'px',
                '--border-radius': this.borderRadius.toString() + 'px',
            }
        },
    },
}
</script>

<style scoped>
.wrapper {
    z-index: 4;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    text-align: center;
    min-width: 110px;
    width: var(--width);
    height: var(--height);
    background: #ffffff;
    border-radius: var(--border-radius);
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    opacity: var(--landscape-image-opacity);
}
</style>
