






















import Vue from 'vue'
export default Vue.extend({
    props: {
        pulseLoop: {
            type: Boolean,
            default: true,
        },
        pulseOnce: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: 'right',
        },
    },
})
