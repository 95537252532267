



































import Vue from 'vue'

export default Vue.extend({
    inheritAttrs: false, // This is so that the placeholder attribute can be manually assigned by us to the <input> element istead of automatically attaching to the top-level div of this component.
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        mask: {
            type: String,
            default: null,
        },
        inputSize: {
            type: Number,
            default: 222,
        },
        showHint: {
            type: Boolean,
            default: false,
        },
        hintDetails: {
            type: Object,
            default: () => {
                return {
                    width: 250,
                    position: 'middleTopLeft',
                    text: 'Hey',
                }
            },
        },
    },
    computed: {
        cssVars() {
            return {
                '--input-width': this.inputSize + 'px',
            }
        },
    },
    methods: {
        updateValue(value: string | number) {
            this.$emit('input', value)
        },
    },
})
