<template>
    <div class="container">
        <vue-slider
            :value="value"
            :style="cssVars"
            class="vue-color-theme"
            :data="hexColorPallete"
            :width="sliderWidth"
            :interval="40"
            :height="20"
            :dot-size="60"
            @change="updateColor"
        >
            <template v-slot:dot>
                <img src="~/assets/sliders/slider_disc_light.svg" class="slider-dot" />
            </template>
            <template v-slot:tooltip>
                <div class="small-box">
                    <div class="tooltip" :style="{ 'background-color': value }"></div>
                </div>
            </template>
        </vue-slider>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import { RGBsToHexs, RGBgradientColor } from '@/helpers/colors'

export default {
    components: {
        VueSlider,
    },
    props: {
        rgbColorPallete: {
            type: Array,
            required: true,
        },
        sliderWidth: {
            type: Number,
            default: 800,
        },
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        hexColorPallete() {
            // Slider was getting sluggish, so now only using every 5th value, it is snappy again.
            return RGBsToHexs(this.rgbColorPallete).filter((_, index) => index % 5 === 0)
        },
        gradientColor() {
            return RGBgradientColor(this.rgbColorPallete)
        },
        cssVars() {
            return {
                '--bg-color': this.gradientColor,
            }
        },
    },
    methods: {
        updateColor(color) {
            this.$emit('input', color)
        },
    },
}
</script>

<style scoped>
.container {
    width: 90%;
    /* position: absolute; */
    top: 50px;
    left: 50px;
}
.slider-dot {
    cursor: pointer;
}
.small-box {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 8px 6px -6px black;
    vertical-align: middle;
}
.tooltip {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    margin-bottom: 2.5px;
    margin-left: 2.5px;
}
</style>

<style>
.vue-color-theme .vue-slider-rail {
    background-color: transparent;
    background-image: var(--bg-color);
}
.vue-color-theme .vue-slider-mark {
    z-index: 4;
    background-color: transparent;
}
.vue-color-theme .vue-slider-process {
    background-color: transparent;
    border-radius: 15px;
}
.vue-color-theme .vue-slider-mark-step {
    background-color: rgba(0, 0, 0, 0);
}
</style>
