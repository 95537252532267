import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { IncomeState, SavingsUnit } from '@/models/incomes.ts'
import { calculateInflation, MAX_AGE } from '@/helpers/inflation'

const initialState: IncomeState = {
    // This monthlyIncome is equivalent to the fund salary concept that was added towards the end of the app creation.
    // It represents gross salary after fundSalaryPercentage has been subtracted.
    monthlyIncome: null,
    salaryInfo: {
        grossSalary: null,
        fundSalaryPercentage: null,
    },
    fundSalaryPercentage: null,
    guaranteedIncomes: [],
    flexibleIncomes: [],
    savingsInfo: {
        afCurrentSavings: null,
        afMonthlyContribution: {
            amount: null,
            unit: null,
        },
        otherFundExpensesPercentage: null,
        otherCurrentSavings: null,
        otherMonthlyContribution: null,
        predictedTotalSavings: null,
    },
    betterWaysOption: {
        jobIncome: {
            jobInitialIncome: null,
            ageDone: null,
        },
        otherInvestmentsIncome: {
            cashAvailable: null,
            propertyAvailable: null,
            sharesAvailable: null,
        },
    },
    flexibleIncomeOption: {
        flexibleIncome: null,
        flexibleOptionRange: [],
        retirementPolicy: null,
        guarantedIncomesChosen: [],
    },
}

export const state = () => ({ ...initialState })

export const getters: GetterTree<IncomeState, RootState> = {
    getSalaryInfo: state => ({ ...state.salaryInfo }),
    getGuaranteedIncomes: state => state.guaranteedIncomes,
    getPredictedSavings: state => state.savingsInfo.predictedTotalSavings,
    getRetirementPolicy: state => state.flexibleIncomeOption.retirementPolicy,
    getJobIncomes: (state, __, _, rootGetters) => {
        const yearsUntilRetirement = rootGetters['user/getRetirementAge'] - rootGetters['user/getAge']
        return calculateInflation(
            state.betterWaysOption.jobIncome?.ageDone! - rootGetters['user/getAge'],
            state.betterWaysOption.jobIncome?.jobInitialIncome!,
        )
            .map(income => income.amount)
            .slice(yearsUntilRetirement)
    },
    getInvestmentIncomes: (state, __, _, rootGetters) => {
        const yearsUntilRetirement = rootGetters['user/getRetirementAge'] - rootGetters['user/getAge']
        const otherInvestmentsTotal = Object.values(state.betterWaysOption.otherInvestmentsIncome!)
            .filter(Boolean)
            .reduce((a, b) => a! + b!, 0)
        return calculateInflation(MAX_AGE - rootGetters['user/getAge'], otherInvestmentsTotal!)
            .map(newInvestmentIncome => newInvestmentIncome.amount)
            .slice(yearsUntilRetirement)
    },
    getAfAbsoluteContribution: state => {
        if (state.savingsInfo.afMonthlyContribution?.unit === SavingsUnit.PERCENTAGE) {
            const monthlyIncome = state.monthlyIncome!
            return parseInt(((monthlyIncome / 100) * state.savingsInfo.afMonthlyContribution.amount!).toFixed(0))
        }
        return state.savingsInfo.afMonthlyContribution?.amount
    },
    // Intercepting the afMonthlyContribution here, to take into account the newly added otherFundExpensesPercentage.
    // Being very explicit with the variables here to clearly show the steps being followed.
    getSavingsInfo: (state, getters) => {
        const otherFundExpensesPercentage = state.savingsInfo.otherFundExpensesPercentage
        const contributionBeforeRemovingFundExpenses = getters.getAfAbsoluteContribution
        const otherFundExpensesRandValue =
            (contributionBeforeRemovingFundExpenses! * otherFundExpensesPercentage!) / 100
        const contributionAfterRemovingFundExpenses =
            contributionBeforeRemovingFundExpenses! - otherFundExpensesRandValue
        return {
            afCurrentSavings: state.savingsInfo.afCurrentSavings,
            afMonthlyContribution: contributionAfterRemovingFundExpenses,
            otherCurrentSavings: state.savingsInfo.otherCurrentSavings,
            otherMonthlyContribution: state.savingsInfo.otherMonthlyContribution,
            otherFundExpensesPercentage: state.savingsInfo.otherFundExpensesPercentage,
            monthlyContributions: { ...state.savingsInfo.afMonthlyContribution },
        }
    },
    getFlexibleIncome: state => state.flexibleIncomeOption.flexibleIncome,
    getChosenGuarantees: state => state.flexibleIncomeOption.guarantedIncomesChosen,
}

export const actions: ActionTree<IncomeState, RootState> = {
    setAfSavingsInfo({ commit }, savings) {
        commit(SET_AFSAVINGS_INFO, savings)
    },
    setSalaryInfo({ commit }, salaryInfo) {
        commit(SET_SALARY_INFO, salaryInfo)
    },
    setOtherSavingsInfo({ commit }, savings) {
        commit(SET_OTHER_SAVINGS_INFO, savings)
    },
    setPredictedTotalSavings({ commit }, predictedTotalSavings) {
        commit(SET_PREDICTED_SAVINGS, predictedTotalSavings)
    },
    setNewRetirementPolicy({ commit }, newRetirementPolicy) {
        commit(SET_NEW_RETIREMENT_POLICY, newRetirementPolicy)
    },
    setGuaranteedIncome({ commit }, guaranteedIncome) {
        commit(SET_GUARANTEED_INCOME, guaranteedIncome)
    },
    setJobIncome({ commit }, jobIncome) {
        commit(SET_JOB_INCOME, jobIncome)
    },
    setInvestmentIncome({ commit }, investmentIncome) {
        commit(SET_INVESTMENT_INCOME, investmentIncome)
    },
    setFlexibleOptionRange({ commit }, flexibleincomes) {
        commit(SET_FLEXIBLE_OPTION_RANGE, flexibleincomes)
    },
    setChosenGuarantees({ commit }, chosenGuarantees) {
        commit(SET_CHOSEN_GUARANTEES, chosenGuarantees)
    },
    setFlexibleIncome({ commit }, flexibleIncome) {
        commit(SET_FLEXIBLE_INCOME, flexibleIncome)
    },
    resetState({ commit }) {
        commit(RESET_STATE)
    },
}

export const SET_AFSAVINGS_INFO = 'SET_AFSAVINGS_INFO'
export const SET_OTHER_SAVINGS_INFO = 'SET_OTHER_SAVINGS_INFO'
export const SET_PREDICTED_SAVINGS = 'SET_PREDICTED_SAVINGS'
export const SET_GUARANTEED_INCOME = 'SET_GUARANTEED_INCOME'
export const SET_JOB_INCOME = 'SET_JOB_INCOME'
export const SET_INVESTMENT_INCOME = 'SET_INVESTMENT_INCOME'
export const SET_FLEXIBLE_OPTION_RANGE = 'SET_FLEXIBLE_OPTION_RANGE'
export const SET_NEW_RETIREMENT_POLICY = 'SET_NEW_RETIREMENT_POLICY'
export const SET_CHOSEN_GUARANTEES = 'SET_CHOSEN_GUARANTEES'
export const SET_FLEXIBLE_INCOME = 'SET_FLEXIBLE_INCOME'
export const RESET_STATE = 'RESET_STATE'
export const SET_SALARY_INFO = 'SET_SALARY_INFO'

export const mutations: MutationTree<IncomeState> = {
    [SET_AFSAVINGS_INFO](state, savings) {
        state.savingsInfo.afCurrentSavings = savings.currentTotal
        state.savingsInfo.afMonthlyContribution = savings.monthlyContributions
        state.savingsInfo.otherFundExpensesPercentage = savings.otherFundExpensesPercentage
    },
    [SET_SALARY_INFO](state, salaryInfo) {
        salaryInfo.fundSalaryPercentage = salaryInfo.fundSalaryPercentage
        state.salaryInfo = salaryInfo
        state.fundSalaryPercentage = salaryInfo.fundSalaryPercentage
        // This is basically a shim to plug in the new salaryInfo with the existing monthlyIncome.
        // Saving all values to store, so that future programmers can intercept and play with as needed.
        // Also so that if a user returns to the form, they see the same values they plugged in, not some calculated value.
        const { grossSalary, fundSalaryPercentage } = salaryInfo
        state.monthlyIncome = (grossSalary * fundSalaryPercentage) / 100
    },
    [SET_OTHER_SAVINGS_INFO](state, savings) {
        state.savingsInfo.otherCurrentSavings = savings.otherCurrentSavings
        state.savingsInfo.otherMonthlyContribution = savings.otherMonthlyContribution
    },
    [SET_PREDICTED_SAVINGS](state, predictedTotalSavings) {
        state.savingsInfo.predictedTotalSavings = predictedTotalSavings
    },
    [SET_GUARANTEED_INCOME](state, guaranteedIncome) {
        state.guaranteedIncomes = guaranteedIncome
    },
    [SET_FLEXIBLE_OPTION_RANGE](state, flexibleOptionRange) {
        state.flexibleIncomeOption.flexibleOptionRange = flexibleOptionRange
    },
    [SET_JOB_INCOME](state, jobIncome) {
        state.betterWaysOption.jobIncome = jobIncome
    },
    [SET_INVESTMENT_INCOME](state, investmentIncome) {
        state.betterWaysOption.otherInvestmentsIncome = investmentIncome
    },
    [SET_NEW_RETIREMENT_POLICY](state, newRetirementPolicy) {
        state.flexibleIncomeOption.retirementPolicy = newRetirementPolicy
    },
    [SET_CHOSEN_GUARANTEES](state, chosenGuarantees) {
        state.flexibleIncomeOption.guarantedIncomesChosen = chosenGuarantees
    },
    [SET_FLEXIBLE_INCOME](state, flexibleIncome) {
        state.flexibleIncomeOption.flexibleIncome = flexibleIncome
    },
    [RESET_STATE](state) {
        Object.assign(state, { ...initialState })
    },
}
