const redirects = [
    {
        from: '/expenses',
        new: '/expenses/add-expenses',
        condition: true,
    },
]

export default function ({ route, redirect }) {
    const path = redirects.find(r => r.from === route.path)
    if (route.path === '/' && !localStorage.getItem('introDone')) {
        localStorage.setItem('introDone', true)
        redirect('301', '/introduction/1')
    }
    if (path) {
        redirect('301', path.new)
    }
}
