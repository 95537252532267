import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { Section, SectionsState, SectionStatus } from '@/models/sections'
import { RootState } from '@/store'
import { _sectionCompletedImages } from '@/store/ui/privateData'

const initialState: SectionsState = {
    [Section.ABOUT_ME]: {
        status: SectionStatus.UNLOCKED,
        subSections: [
            { label: '1. Personal Info', link: '/about-me/personal-info', locked: false },
            { label: '2. Intro', link: '/about-me/intro', locked: true },
            { label: '3. Edit Hair and Skin', link: '/about-me/hair-skin', locked: true },
            { label: '4. Edit Outfit', link: '/about-me/outfit', locked: true },
        ],
    },
    [Section.MY_RETIREMENT_SAVINGS]: {
        status: SectionStatus.LOCKED,
        subSections: [
            { label: '1. Salary', link: '/my-retirement-savings/salary', locked: false },
            { label: '2. Contributions', link: '/my-retirement-savings/contributions', locked: true },
            {
                label: '3. Other Retirement Savings',
                link: '/my-retirement-savings/additional-savings/intro',
                locked: true,
            },
            {
                label: '4. Predicted Total Savings',
                link: '/my-retirement-savings/predicted-total-savings',
                locked: true,
            },
        ],
    },
    [Section.GUARANTEED_INCOME_OPTION]: {
        status: SectionStatus.LOCKED,
        subSections: [
            { label: '1. Guaranteed Pension', link: '/guaranteed-income-option/life-annuity-policy', locked: false },
            {
                label: '2. Predicted Income',
                link: '/guaranteed-income-option/predicted-income',
                locked: true,
            },
            {
                label: '3. Effects of Inflation',
                link: '/guaranteed-income-option/adjusting-for-inflation',
                locked: true,
            },
            {
                label: '4. Advantages and Disadvantages',
                link: '/guaranteed-income-option/advantages-and-disadvantages',
                locked: true,
            },
        ],
    },
    [Section.EXPENSES]: {
        status: SectionStatus.LOCKED,
        subSections: [
            { label: '1. Add Expenses', link: '/expenses/add-expenses', locked: false },
            { label: '2. Explore Inflation', link: '/expenses/explore-inflation/intro', locked: false },
            { label: '3. Explore Medical Costs', link: '/expenses/explore-medical-costs/intro', locked: false },
        ],
    },
    [Section.WAYS_TO_A_BETTER_RETIREMENT]: {
        status: SectionStatus.LOCKED,
        subSections: [
            { label: '1. Intro', link: '/ways-to-a-better-retirement/intro-info', locked: false },
            {
                label: '2. Prioritise Expenses',
                link: '/ways-to-a-better-retirement/prioritise-expenses',
                locked: false,
            },
            { label: '3. Retirement Budget', link: '/ways-to-a-better-retirement/intro-landscape', locked: false },
            {
                label: '4. Make Your Pension Go Further',
                link: '/ways-to-a-better-retirement/reduce-expenses-intro',
                locked: false,
            },
            { label: '5. Reduce Expenses', link: '/ways-to-a-better-retirement/option-1', locked: false },
            { label: '6. Delay Retirement', link: '/ways-to-a-better-retirement/option-2', locked: false },
            { label: '7. Earn Additional Income', link: '/ways-to-a-better-retirement/option-3', locked: false },
            { label: '8. Sell other Investments', link: '/ways-to-a-better-retirement/option-4', locked: false },
            { label: '9. Government Grants', link: '/ways-to-a-better-retirement/option-5', locked: true },
            { label: '10. Combined Options', link: '/ways-to-a-better-retirement/final-landscape', locked: false },
        ],
    },
    [Section.FLEXIBLE_INCOME_OPTION]: {
        status: SectionStatus.LOCKED,
        subSections: [],
    },
    [Section.FULL_RETIREMENT_PICTURE]: {
        status: SectionStatus.LOCKED,
        subSections: [],
    },
    [Section.GET_ADVICE]: {
        status: SectionStatus.COMPLETED,
        subSections: [],
    },
}
export const state = () => ({ ...initialState })

export const getters: GetterTree<SectionsState, RootState> = {
    getMenuItemStatus: state => (section: Section) => state[section].status,
    getSectionImage: _ => (section: Section) => _sectionCompletedImages[section],
    getSubSections: state => (section: Section) => state[section].subSections,
    isNextSection: (_, __, rootState: any) => (section: Section) => {
        return rootState.ui.nextSection === section
    },
}

export const actions: ActionTree<SectionsState, RootState> = {
    finishSection({ commit, state, dispatch }, section: Section) {
        // Only finish a section and set nextSection if the user hasn't already finished this section before.
        if (state[section].status !== SectionStatus.COMPLETED) {
            const sectionsInOrder = Object.keys(state)
            const indexOfThisSection = sectionsInOrder.indexOf(section)
            const nextSection = sectionsInOrder[indexOfThisSection + 1] as Section
            dispatch('ui/setNextSection', nextSection, { root: true })
            commit(SET_SECTION_STATUS, { section, status: SectionStatus.COMPLETED })
            dispatch('startUnlockingSection', nextSection)
        }
    },
    startUnlockingSection({ commit }, section: Section) {
        commit(SET_SECTION_STATUS, { section, status: SectionStatus.UNLOCKING })
    },
    finishUnlockingSection({ commit }, section: Section) {
        commit(SET_SECTION_STATUS, { section, status: SectionStatus.UNLOCKED })
    },
    finishSubSection({ commit }, link) {
        commit(FINISH_SUBSECTION, link)
    },
    resetState({ commit }) {
        commit(RESET_STATE)
    },
    // Temporary action used to test menu animation on a single menu item in a sandbox.
    // So that we don't need to keep filling in forms to test the unlocking flow.
    setSectionStatus({ commit }, status) {
        commit(SET_SECTION_STATUS, {
            section: Section.MY_RETIREMENT_SAVINGS,
            status,
        })
    },
}
export const FINISH_SUBSECTION = 'FINISH_SUBSECTION'
export const RESET_STATE = 'RESET_STATE'
export const SET_SECTION_STATUS = 'SET_MENU_STATUS'

export const mutations: MutationTree<SectionsState> = {
    [SET_SECTION_STATUS](state, sectionAndValue: { section: Section; status: SectionStatus }) {
        state[sectionAndValue.section] = {
            ...state[sectionAndValue.section],
            status: sectionAndValue.status,
        }
    },
    [FINISH_SUBSECTION](state, link) {
        const section: Section = link.split('/')[1].toUpperCase()
        const subSections = state[section].subSections
        const subsectionIndex = subSections.findIndex((x: any) => link.startsWith(x.link))
        state[section].subSections[subsectionIndex + 1].locked = false
    },
    [RESET_STATE](state) {
        Object.assign(state, { ...initialState })
    },
}
