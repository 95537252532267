import { Section } from '@/models/sections'
export type UiState = {
    visibleModals: string[]
    onceOffButSeenModals: string[]
    showSliderPrompt: boolean
    nextSection: Section
    introSubpage: number
}

export enum Modal {
    BetterWaysExpense = 'BetterWaysExpense',
    PleaseRotate = 'PleaseRotate',
    PleaseBiggerScreen = 'PleaseBiggerScreen',
    BetterWaysOutro = 'BetterWaysOutro',
    CombinedEditExpensesModal = 'CombinedEditExpensesModal',
    FlexiExploreIncomeModal = 'FlexiExploreIncomeModal',
    Expense = 'Expense',
    ExpensesDone = 'ExpensesDone',
    ExpensesIntro = 'ExpensesIntro',
    EditCostPercentage = 'EditCostPercentage',
    FlexiIncomeDeficitModal = 'FlexiIncomeDeficitModal',
    FlexiIncomeIntroModal = 'FlexiIncomeIntroModal',
    GuaranteedExpensesModal = 'GuaranteedExpensesModal',
    AboutMeIntroModal = 'AboutMeIntroModal',
}

// These strings need to be lower-case because they need to line up with the following via interpolation:
// slot="tab-head-prioritise"    ~   `tab-head-${ModalTab.Edit}`
export enum ModalTab {
    Edit = 'edit',
    Prioritise = 'prioritise',
    Guarantee = 'guarantee',
}
