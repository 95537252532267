import cuid from 'cuid'
import { getCookieValue, setCookieValue } from '@/helpers/cookies'

const plugin = () => {
    const COOKIE_NAME = 'userID'

    const currentUserId = getCookieValue(COOKIE_NAME)

    const createUserId = () => {
        const userId = cuid()
        const expiryDate = new Date()
        expiryDate.setMonth(expiryDate.getMonth() + 12)
        setCookieValue(COOKIE_NAME, userId, expiryDate)
    }

    if (!currentUserId) {
        createUserId()
    }
}

plugin()
