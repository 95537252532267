<template>
    <div>
        <BreadCrumbMenu :previous-route="previousRoute" :current-route="currentRoute" :next-routes="nextRoutes" />
        <transition enter-active-class="animate__animated animate__fadeIn">
            <nuxt />
        </transition>
        <please-bigger-screen-modal :value="mustShowModal(Modal.PleaseBiggerScreen)" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PleaseBiggerScreenModal from '@/components/Modals/PleaseBiggerScreenModal.vue'
import BreadCrumbMenu from '@/components/BreadCrumbMenu'
// import PleaseRotateModal from '@/components/Modals/PleaseRotateModal.vue'
import { Section } from '@/models/sections'
import { Modal } from '@/models/ui'
import { BreakPointLimits } from '@/helpers/constants'
export default {
    components: {
        BreadCrumbMenu,
        // PleaseRotateModal,
        PleaseBiggerScreenModal,
    },
    data() {
        return {
            index: 0,
            Modal,
        }
    },
    computed: {
        ...mapGetters('ui', ['mustShowModal']),
        subSections() {
            const splitRoutePath = this.$route.path.split('/')
            if (Object.values(Section).includes(splitRoutePath[1].toUpperCase())) {
                return this.$store.getters['sections/getSubSections'](splitRoutePath[1].toUpperCase())
            }
            return []
        },
        currentSection() {
            const subSection = this.subSections.find(x => this.$route.path.startsWith(x.link))
            if (!subSection) {
                return null
            }

            return { index: this.subSections.indexOf(subSection) }
        },
        currentRoute() {
            if (this.currentSection) {
                return {
                    link: this.subSections[this.currentSection.index].link,
                    label: this.subSections[this.currentSection.index].label,
                }
            }
            return null
        },
        previousRoute() {
            if (!this.currentSection || this.currentSection.index === 0) {
                return null
            }
            return this.subSections[this.currentSection.index - 1]
        },
        nextRoutes() {
            if (this.currentSection) {
                return this.subSections.slice(this.currentSection.index + 1)
            }
            return []
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            const widthOfShortestSideOfIpad = BreakPointLimits.MIN_WIDTH
            const heightOfSmallestLaptop = BreakPointLimits.MIN_HEIGHT
            const isTooSmall =
                window.innerWidth < widthOfShortestSideOfIpad || window.innerHeight < heightOfSmallestLaptop
            if (isTooSmall) {
                // Even though we have an early return for isTooSmall;
                // This hiding of PleaseRotate covers the case of a tester who moves from portrait to isTooSmall.
                this.$store.dispatch('ui/hideModal', Modal.PleaseRotate)
                this.$store.dispatch('ui/showModal', Modal.PleaseBiggerScreen)
                // The return ensures that this modal gets prioritised over the isPortrait based one.
                return
            } else {
                this.$store.dispatch('ui/hideModal', Modal.PleaseBiggerScreen)
            }
            const isPortrait = window.innerWidth < window.innerHeight
            if (isPortrait) {
                this.$store.dispatch('ui/showModal', Modal.PleaseRotate)
            } else {
                this.$store.dispatch('ui/hideModal', Modal.PleaseRotate)
            }
        },
    },
}
</script>
<style>
html {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
}

.button--green {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #3b8070;
    color: #3b8070;
    text-decoration: none;
    padding: 10px 30px;
}

.button--green:hover {
    color: #fff;
    background-color: #3b8070;
}

.button--grey {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #35495e;
    color: #35495e;
    text-decoration: none;
    padding: 10px 30px;
    margin-left: 15px;
}

.button--grey:hover {
    color: #fff;
    background-color: #35495e;
}
</style>
