
































import Vue from 'vue'
import Lottie from 'vue-lottie'

export default Vue.extend({
    components: {
        lottie: Lottie,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            animation: null,
            showPageMask: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(showMe) {
                if (showMe) {
                    this.showPageMask = true
                } else {
                    setTimeout(() => {
                        this.showPageMask = false
                    }, 500)
                }
            },
        },
    },
    methods: {
        handleAnimation(animation: any) {
            this.animation = animation
        },
    },
})
