import { GetterTree, ActionTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { RootState } from '@/store'
import {
    UserState,
    Gender,
    FemaleHairSelectionIdentifier,
    MaleHairSelectionIdentifier,
    FemaleOutfitSelectionIdentifier,
    MaleOutfitSelectionIdentifier,
} from '@/models/user'
import {
    _femaleOutfitSelections,
    _femaleHairSelections,
    _maleHairSelections,
    _maleOutfitSelections,
} from '@/store/ui/privateData.ts'

const initialState: UserState = {
    personalInfo: {
        fullName: null,
        gender: Gender.Female,
        age: null,
        retirementAge: null,
    },
    avatar: {
        hair: null,
        body: null,
        colors: {
            skin: '#a16949',
            outfit: '#63b75a',
            hair: '#563e3b',
        },
    },
}
export const state = () => ({ ...initialState })

export const getters: GetterTree<UserState, RootState> = {
    getFullName: state => state.personalInfo.fullName,
    getAge: state => state.personalInfo.age,
    getRetirementAge: state => state.personalInfo.retirementAge,
    getGender: state => state.personalInfo.gender,
    outfitOptions: _ => (gender: Gender) => (gender === Gender.Male ? _maleOutfitSelections : _femaleOutfitSelections),
    hairOptions: _ => (gender: Gender) => (gender === Gender.Male ? _maleHairSelections : _femaleHairSelections),
}

export const actions: ActionTree<UserState, RootState> = {
    setUser({ commit }, user) {
        commit(SET_USER, user)
    },
    setGender({ commit, dispatch }, gender) {
        commit(SET_GENDER, gender)
        dispatch('resetAvatarToDefaults')
    },
    setSkinTone({ commit }, skinTone) {
        commit(SET_SKIN_TONE, skinTone)
    },
    setHairColor({ commit }, hairColor) {
        commit(SET_HAIR_COLOR, hairColor)
    },
    setOutfitColor({ commit }, outfitColor) {
        commit(SET_OUTFIT_COLOR, outfitColor)
    },
    setHairStyle({ commit }, hairStyle) {
        commit(SET_HAIR_STYLE, hairStyle)
    },
    setOutfit({ commit }, outfit) {
        commit(SET_OUTFIT, outfit)
    },
    setRetirementAge({ commit }, retirementAge) {
        commit(SET_RETIREMENT_AGE, retirementAge)
    },
    resetAvatarToDefaults({ dispatch, state }) {
        if (state.personalInfo.gender === Gender.Male) {
            dispatch('setHairStyle', MaleHairSelectionIdentifier.CleanCut)
            dispatch('setOutfit', MaleOutfitSelectionIdentifier.Flannel)
            // #A16949
        } else {
            dispatch('setHairStyle', FemaleHairSelectionIdentifier.Curly)
            dispatch('setOutfit', FemaleOutfitSelectionIdentifier.Dress)
        }
    },
}

export const SET_USER = 'SET_USER'
export const SET_GENDER = 'SET_GENDER'
export const SET_SKIN_TONE = 'SET_SKIN_TONE'
export const SET_HAIR_COLOR = 'SET_HAIR_COLOR'
export const SET_OUTFIT_COLOR = 'SET_OUTFIT_COLOR'
export const SET_HAIR_STYLE = 'SET_HAIR_STYLE'
export const SET_OUTFIT = 'SET_OUTFIT'
export const SET_RETIREMENT_AGE = 'SET_RETIREMENT_AGE'

export const mutations: MutationTree<UserState> = {
    [SET_USER](state, user) {
        state.personalInfo = user
    },
    [SET_GENDER](state, gender) {
        Vue.set(state.personalInfo, 'gender', gender)
    },
    [SET_SKIN_TONE](state, skinTone) {
        Vue.set(state.avatar.colors, 'skin', skinTone)
    },
    [SET_HAIR_COLOR](state, hairColor) {
        Vue.set(state.avatar.colors, 'hair', hairColor)
    },
    [SET_OUTFIT_COLOR](state, outfitColor) {
        Vue.set(state.avatar.colors, 'outfit', outfitColor)
    },
    [SET_HAIR_STYLE](state, hairStyle) {
        Vue.set(state.avatar, 'hair', hairStyle)
    },
    [SET_OUTFIT](state, outfit) {
        Vue.set(state.avatar, 'body', outfit)
    },
    [SET_RETIREMENT_AGE](state, retirementAge) {
        state.personalInfo.retirementAge = retirementAge
    },
}
