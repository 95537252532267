export enum FemaleHairSelectionIdentifier {
    Bob = 'Bob',
    Bun = 'Bun',
    Curly = 'FemaleCurly',
    Scarf = 'Scarf',
    Hijab = 'Hijab',
    LongStraight = 'LongStraight',
    ShortStraight = 'ShortStraight',
    SunHat = 'SunHat',
}

export enum FemaleOutfitSelectionIdentifier {
    Cardigan = 'Cardigan',
    Dress = 'Dress',
    Knit = 'Knit',
    Loose = 'Loose',
    Poncho = 'Poncho',
    Puffer = 'Puffer',
    Shweshwe1 = 'Shweshwe1',
    Shweshwe2 = 'Shweshwe2',
}

export enum MaleOutfitSelectionIdentifier {
    Africa = 'Africa',
    Flannel = 'Flannel',
    Golf = 'Golf',
    Khuta = 'Khuta',
    LongSleeve = 'LongSleeve',
    ShortSleeve = 'ShortSleeve',
    VeldFocus = 'VeldFocus',
}

export enum MaleHairSelectionIdentifier {
    Cap = 'Cap',
    CleanCut = 'CleanCut',
    Mustache = 'Mustache',
    CombBack = 'CombBack',
    Curly = 'MaleCurly',
    Fedora = 'Fedora',
    FlatCap = 'FlatCap',
    Cropped = 'Cropped',
}

export type avatarSelection = {
    identifier: any
    pathToThumbnail: string
}

export enum Gender {
    Male = 'Male',
    Female = 'Female',
}

export type Avatar = {
    hair: FemaleHairSelectionIdentifier | MaleHairSelectionIdentifier | null
    body: FemaleOutfitSelectionIdentifier | MaleOutfitSelectionIdentifier | null
    colors: {
        skin: string
        outfit: string
        hair: string
    }
}

export type PersonalInfo = {
    fullName: string | null
    gender: string | null
    age: number | null
    retirementAge: number | null
}

export type UserState = {
    personalInfo: PersonalInfo
    avatar: Avatar
}
