import { Expense, ExpenseIdentifier } from '~/models/expenses'

// export const INFLATION_RATE = 5.17 / 100
export const INFLATION_RATE = 0
export const GUARANTEED_INFLATION_RATE = 2 / 100
export const MAX_AGE = 120
export const MAX_YEARS_FOR_MEDICAL_INFLATION = 79

export function calculateInflation(
    numberOfyears: number,
    monthlyCost: number,
): Array<{ year: number; amount: number }> {
    let amount = monthlyCost
    let year = 0
    const inflationRange = [{ year, amount }]
    for (year = 1; year <= numberOfyears; year++) {
        amount = Math.round(amount * (INFLATION_RATE + 1))
        inflationRange.push({
            year,
            amount,
        })
    }
    return inflationRange
}

export function calculateMedicalInflation(
    currentAge: number,
    maximumAge: number,
    monthlyCost: number,
): Array<{ year: number; amount: number }> {
    let amount = monthlyCost
    let year = 0
    const inflationRange = [{ year, amount }]
    for (currentAge; currentAge < maximumAge; currentAge++) {
        year++
        if (currentAge < 85) {
            amount = Math.round(amount * (INFLATION_RATE + 1))
        } else {
            amount = Math.round(amount * ((2 / 100) * 2 + 1))
        }
        inflationRange.push({
            year,
            amount,
        })
    }
    return inflationRange
}

// using this helper to fetch sliderStart and SiderEnd for all slider events, utilisng Dylan's InflationRange setup for this.
export function calculateTotalCostOfExpense(
    numberOfYears: number,
    expenses: Expense[],
    ageToday: number,
    yearsFromToday: number,
    forecastedYear: number,
): number {
    return expenses
        .map(item => {
            const newCost: { year: number; amount: number }[] =
                item.identifier === ExpenseIdentifier.Medical
                    ? calculateMedicalInflation(ageToday, MAX_AGE, item.monthlyCost!)
                    : calculateInflation(numberOfYears, item.monthlyCost!)
            return {
                ...item,
                monthlyCost:
                    item.willThisBecomeLess && forecastedYear > item.lessPaymentYear!
                        ? item.lessPaymentMonthlyCost
                        : newCost[yearsFromToday].amount,
            }
        })
        .reduce((total: number, expense: Expense) => {
            return total + expense.monthlyCost!
        }, 0)
    // this reduce returns a value of the total expense at a given year, the arguments allow the user to pass whatever value required to get the total expenses returned
}

export function calculateFlexibleIncomeMoneyLeft(
    totalInflatedExpenses: number,
    totalFlexiSavings: number,
    jobIncome: number | 0,
    guaranteedIncome: number | 0,
): number {
    // add both guaranteed chosen expenses and the job income
    let combinedIncome = jobIncome + guaranteedIncome
    const deficit = combinedIncome - totalInflatedExpenses

    if (deficit < 0) {
        // added the deficit conditional statement to see if the deficit becomes negative, then we start pulling from the flexi income
        const rawPercentage = ((Math.abs(deficit) * 12) / totalFlexiSavings) * 100
        const percentageOfTotalSavings =
            rawPercentage <= 2 ? 2 : rawPercentage >= 15 ? 15 : Number(rawPercentage.toFixed(6))
        combinedIncome = combinedIncome + (percentageOfTotalSavings / 12) * (totalFlexiSavings / 100)
    }

    // return a deficit of the totalInflatedExpense from the monthlyIncome, this gives the money left at given point in time needed for slider event.
    return Math.round(combinedIncome - totalInflatedExpenses)
}

export function calculateDrawDownValues(retirementValues: any[], retirementSavings: number): number[] {
    const drawDownValues = [retirementSavings - retirementSavings * retirementValues[0].income.drawndownPercentage]
    for (let i = 1; i < 79; i++) {
        drawDownValues.push(
            drawDownValues[i - 1] - drawDownValues[i - 1] * retirementValues[i].income.drawndownPercentage,
        )
    }

    return drawDownValues
}
