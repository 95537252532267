import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { GetAdviceDataState } from '~/models/getAdviceData'

export interface LeadsData {
    mobileNumber: string | null
    personalEmail: string | null
    shareData: boolean
}

const initialState: LeadsData = {
    mobileNumber: null,
    personalEmail: null,
    shareData: false,
}

export interface LeadsEndpointInput {
    system: string
    systemDescription: string
    request: string

    mobileNumber: string
    personalEmail: string

    age: number
    firstName: string
    surname: string

    retirementsValues: GetAdviceDataState | null

    opportunityId: string
    identityValue: string
    passpostNumber: string
    identityTypeId: string
    clientDocumentPdfUrl: string
    telephoneNumber: string
    workEmail: string
    primaryEmail: string
    employerName: string
    title: string
    initials: string
    dateOfBirth: string
    contactMethod: string
    maritalStatus: string
    city: string
    clientMessage: string
    product: string
    retirementFund: string
    withdrawalAmount: string
    transferAmount: string
    currentRetirementSavings: string
    previousWithdrawals: string
    lastDay: string
}

export const state = () => ({ ...initialState })

export const SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_SHARE_DATA = 'SET_SHARE_DATA'

export const getters: GetterTree<LeadsData, RootState> = {
    getMobileNumber: state => state.mobileNumber,
    getPersonalEmail: state => state.personalEmail,
    getShareData: state => state.shareData,
}

export const actions: ActionTree<LeadsData, RootState> = {
    setShareData({ commit }, shareData) {
        commit(SET_SHARE_DATA, shareData)
    },
    setContactDetails({ commit }, contactDetails) {
        commit(SET_CONTACT_DETAILS, contactDetails)
    },
    setEmail({ commit }, email) {
        commit(SET_EMAIL, email)
    },
    async sendLeads({ commit }, { $config, $loading, excludeCalcData = true }) {
        const fullName = this.getters['user/getFullName']
        const firstName = fullName.substr(0, fullName.indexOf(' '))
        const surname = fullName.substring(firstName.length + 1)

        const input: LeadsEndpointInput = {
            system: 'Red Carpet',
            systemDescription: 'Retirements – My Retirement Picture',
            request: 'Advice',

            mobileNumber: this.getters['leads/getMobileNumber'],
            personalEmail: this.getters['leads/getPersonalEmail'],
            age: Number(this.getters['user/getAge']),

            firstName,
            surname,

            retirementsValues: null,

            opportunityId: '',
            identityValue: '',
            passpostNumber: '',
            identityTypeId: '',
            clientDocumentPdfUrl: '',
            telephoneNumber: '',
            workEmail: '',
            primaryEmail: '',
            employerName: '',
            title: '',
            initials: '',
            dateOfBirth: '',
            contactMethod: '',
            maritalStatus: '',
            city: '',
            clientMessage: '',
            product: '',
            retirementFund: '',
            withdrawalAmount: '',
            transferAmount: '',
            currentRetirementSavings: '',
            previousWithdrawals: '',
            lastDay: '',
        }

        if (this.getters['leads/getShareData']) {
            input.retirementsValues = this.getters['adviceData/getState']
        }

        $loading.start()
        await this.$axios.$post($config.LeadsEndpoint, input)
        setTimeout(() => $loading.finish(), 500)
    },
    async downloadTakeawayDocument({ commit }, { $config, $loading, img }) {
        $loading.start()

        const input = {
            ...this.getters['adviceData/getState'],
            avatar: img
        }

        await this.$axios({
            url: `${$config.DocumentApiEndpoint}documents/download`,
            method: 'post',
            responseType: 'blob',
            data: input
        })
        .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
        
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'takeaway-document.pdf');
            document.body.appendChild(fileLink);
        
            fileLink.click();
        })

        setTimeout(() => $loading.finish(), 500)
    },
    async emailDocument({ commit }, { $config, $loading, img }) {
        $loading.start()

        const input = {
            ...this.getters['adviceData/getState'],
            email: this.getters['leads/getPersonalEmail'],
            name: this.getters['user/getFullName'],
            avatar: img
        }

        await this.$axios.$post(`${$config.DocumentApiEndpoint}documents/send`, input)

        setTimeout(() => $loading.finish(), 500)
    },
}

export const mutations: MutationTree<LeadsData> = {
    [SET_CONTACT_DETAILS](state, contactDetails) {
        state.mobileNumber = contactDetails.mobileNumber
        state.personalEmail = contactDetails.personalEmail
    },
    [SET_EMAIL](state, email) {
        state.personalEmail = email
    },
    [SET_SHARE_DATA](state, shareData) {
        state.shareData = shareData
    },
}
