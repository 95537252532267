export function RGBToHex(r, g, b) {
    r = r.toString(16)
    g = g.toString(16)
    b = b.toString(16)

    if (r.length === 1) {
        r = '0' + r
    }
    if (g.length === 1) {
        g = '0' + g
    }
    if (b.length === 1) {
        b = '0' + b
    }

    return '#' + r + g + b
}
export function RGBStringToRGB(rgbStringArray) {
    const rgbArray = []
    // Loop through RGB String Array
    rgbStringArray.forEach(rgb => {
        // Choose correct separator
        const separator = rgb.includes(',') > -1 ? ',' : ' '
        // Turn "rgb(r,g,b)" into [r,g,b]
        rgb = rgb
            .substr(4)
            .split(')')[0]
            .split(separator)
        rgbArray.push(rgb)
    })
    return rgbArray
}

export function RGBsToHexs(rgbColorPallete) {
    // set RGBcolor type
    function RGBColor(r, g, b) {
        return {
            r: r || 0,
            g: g || 0,
            b: b || 0,
        }
    }
    const hexColorList = []
    let tmpColor
    // Spectrum Length is the percentage of the spectrum each color will take up
    const spectrumLength = parseInt(255 / rgbColorPallete.length)
    // iterating through each color and then creating a range between them by setting the start color and the end color
    for (let k = 0; k < rgbColorPallete.length - 1; k++) {
        const startColor = RGBColor(rgbColorPallete[k][0], rgbColorPallete[k][1], rgbColorPallete[k][2])
        const endColor = RGBColor(rgbColorPallete[k + 1][0], rgbColorPallete[k + 1][1], rgbColorPallete[k + 1][2])
        // This for loop basically sets the range and spectrumLength defines how many coors are set to that specific range
        for (let i = 0; i < spectrumLength; i++) {
            tmpColor = new RGBColor()
            tmpColor.r = parseInt(startColor.r + (i * (endColor.r - startColor.r)) / spectrumLength)
            tmpColor.g = parseInt(startColor.g + (i * (endColor.g - startColor.g)) / spectrumLength)
            tmpColor.b = parseInt(startColor.b + (i * (endColor.b - startColor.b)) / spectrumLength)
            // converts RGB range to HEX
            const hexColor = RGBToHex(tmpColor.r, tmpColor.g, tmpColor.b)
            hexColorList.push(hexColor)
        }
    }
    return hexColorList
}

export function RGBgradientColor(rgbColorPallete) {
    // this method is returning a Linear Gradient to the css by iterating over the RGB array and converting it into one long string
    let gradientSpectrum = 'linear-gradient(90deg'
    const spectrumPercent = parseInt((1 / (rgbColorPallete.length - 1)) * 100)
    let percentage = 0
    rgbColorPallete.forEach(value => {
        gradientSpectrum =
            gradientSpectrum + ',rgba(' + value[0] + ',' + value[1] + ',' + value[2] + ')' + ' ' + percentage + '%'
        percentage += spectrumPercent
    })
    gradientSpectrum = gradientSpectrum + ')'
    return gradientSpectrum
}
// Not sure if we using this function but leaving it just in case
export function RGBToHSL(r, g, b) {
    // Make r, g, and b fractions of 1
    r /= 255
    g /= 255
    b /= 255

    // Find greatest and smallest channel values
    const cmin = Math.min(r, g, b)
    const cmax = Math.max(r, g, b)
    const delta = cmax - cmin
    let h = 0
    let s = 0
    let l = 0
    // Calculate hue
    // No difference
    if (delta === 0) {
        h = 0
    }
    // Red is max
    else if (cmax === r) {
        h = ((g - b) / delta) % 6
    }
    // Green is max
    else if (cmax === g) {
        h = (b - r) / delta + 2
    }
    // Blue is max
    else {
        h = (r - g) / delta + 4
    }

    h = Math.round(h * 60)

    // Make negative hues positive behind 360°
    if (h < 0) {
        h += 360
    }

    // Calculate lightness
    l = (cmax + cmin) / 2

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return 'hsl(' + h + ',' + s + '%,' + l + '%)'
}
