






import Vue from 'vue'
export default Vue.extend({
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            default: 'black-square',
        },
        block: {
            // Allows consumer to add block attribut to element resulting in the button filling available width.  Any existing button can therefore be a block-button.
            type: Boolean,
            default: false,
        },
    },
})
