export function getCookieValue(name) {
    const cookie = `; ${document.cookie}`
    const parts = cookie.split(`; ${name}=`)
    if (parts.length !== 2) {
        return null
    }
    return parts
        .pop()
        .split(';')
        .shift()
}

export function setCookieValue(name, value, expiryDate) {
    document.cookie = `${name}=${value};expires=${expiryDate};domain=${window.location.hostname};path=/`
}
