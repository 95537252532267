import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import {
    Expense,
    Expenses,
    GrossRetirementSavingsCalcInput,
    MedicalExpensesCalcInput,
    NetRetirementSavingsCalcInput,
    PostRetirementCalcResultRecord,
    RetirementIncomeCalcInput,
    RetirementIncomeCalcResultRecord,
    RetirementSavingsCalcInput,
} from '~/models/retirementCalculatorModels'
import { SavingsUnit } from '~/models/incomes'
import { ExpenseIdentifier } from '~/models/expenses'

export interface CalculatorData {
    postRetirementValues: { [key: number]: PostRetirementCalcResultRecord }
    medicalExpenses: { [key: string]: number }
    retirementSavings: number | null
    retirementSavingsNet: number | null
    retirementSavingsGross: number | null
    retirementIncome: RetirementIncomeCalcResultRecord[] | null
    otherIncome: number | null
    ageOtherIncomeEnds: number | null
    otherInvestmentValues: {
        cashAvailable: number | null
        propertyAvailable: number | null
        sharesAvailable: number | null
    }
    percentageToGuaranteed: number
    postRetirementValuesExpenses: Expense[][]
}

const initialState: CalculatorData = {
    percentageToGuaranteed: 1,
    retirementSavings: null,
    retirementSavingsNet: null,
    retirementSavingsGross: null,
    retirementIncome: null,
    postRetirementValues: {},
    postRetirementValuesExpenses: [],
    medicalExpenses: {},
    otherIncome: null,
    ageOtherIncomeEnds: null,
    otherInvestmentValues: {
        cashAvailable: null,
        propertyAvailable: null,
        sharesAvailable: null,
    },
}

export const state = () => ({ ...initialState })

export const SET_RETIREMENT_SAVINGS = 'SET_RETIREMENT_SAVINGS'
export const SET_GROSS_RETIREMENT_SAVINGS = 'SET_GROSS_RETIREMENT_SAVINGS'
export const SET_NET_RETIREMENT_SAVINGS = 'SET_NET_RETIREMENT_SAVINGS'
export const SET_RETIREMENT_INCOME = 'SET_RETIREMENT_INCOME'
export const SET_MEDICAL_EXPENSES = 'SET_MEDICAL_EXPENSES'
export const SET_INCOME_SHORTFALL = 'SET_INCOME_SHORTFALL'
export const SET_POSTRETIREMENT = 'SET_POSTRETIREMENT'
export const SET_SHORTFALL_REDUCED_EXPENSES = 'SET_SHORTFALL_REDUCED_EXPENSES'
export const SET_SHORTFALL_RETIRE_LATER = 'SET_SHORTFALL_RETIRE_LATER'
export const SET_SHORTFALL_OTHER_INCOME = 'SET_SHORTFALL_OTHER_INCOME'
export const SET_OTHER_INCOME = 'SET_OTHER_INCOME'
export const SET_AGE_OTHER_INCOME_ENDS = 'SET_AGE_OTHER_INCOME_ENDS'
export const SET_OTHER_INVESTMENT_VALUES = 'SET_OTHER_INVESTMENT_VALUES'
export const SET_SHORTFALL_OTHER_INVESTMENTS = 'SET_SHORTFALL_OTHER_INVESTMENTS'
export const SET_POST_RETIREMENT_VALUES = 'SET_POST_RETIREMENT_VALUES'
export const SET_PERCENTAGE_TO_GUARANTEED = 'SET_PERCENTAGE_TO_GUARANTEED'

export const getters: GetterTree<CalculatorData, RootState> = {
    mustBeRed: state => (identifier: ExpenseIdentifier, incomeIndex: number) => {
        if (!(incomeIndex >= 0)) {
            return false
        }

        const expense = state.postRetirementValuesExpenses[incomeIndex].find(
            (exp: any) => exp.expenseCategory === identifier,
        )

        return !(expense && expense.amount > 0)
    },
    getPercentageToGuaranteed: state => state.percentageToGuaranteed,
    getOtherInvestmentValues: state => state.otherInvestmentValues,
    getOtherInvestmentValuesTotal: state =>
        Number(state.otherInvestmentValues.cashAvailable) +
        Number(state.otherInvestmentValues.propertyAvailable) +
        Number(state.otherInvestmentValues.sharesAvailable),
    getOtherIncome: state => state.otherIncome,
    getAgeOtherIncomeEnds: state => state.ageOtherIncomeEnds,
    getRetirementSavings: state => state.retirementSavings,
    getGrossRetirementSavings: state => state.retirementSavingsGross,
    getNetRetirementSavings: state => state.retirementSavingsNet,
    getRetirementIncome: state => state.retirementIncome,
    getMedicalExpenseInflationForYear: state => (year: string) => state.medicalExpenses[year],
    getMedicalExpenses: state => {
        return Object.entries(state.medicalExpenses).map(arr => {
            return {
                year: Number(arr[0]),
                amount: arr[1],
            }
        })
    },
    getPostRetirementInput: (state, __, _, rootGetters) => {
        return {
            retirementAge: Number(rootGetters['user/getRetirementAge']),
            currentAge: Number(rootGetters['user/getAge']),
            gender: String(rootGetters['user/getGender']).toLocaleLowerCase(),
            otherIncome: state.otherIncome ? Number(state.otherIncome) : 0,
            ageOtherIncomeEnds: state.ageOtherIncomeEnds ? Number(state.ageOtherIncomeEnds) : null,
            retirementSavings: state.retirementSavings,
            percentageToGuaranteed: state.percentageToGuaranteed,
            expenses: rootGetters['zoomUi/getExpenses'].reduce((expenses: any, expense: any, index: number) => {
                if (!expense.originalMonthlyCost) {
                    return expenses
                }

                expenses[expense.identifier] = {} as Expense
                expenses[expense.identifier].amount = expense.monthlyCost
                expenses[expense.identifier].priority = index + 1
                expenses[expense.identifier].amountExpenseDecreases = expense.lessPaymentMonthlyCost
                    ? expense.monthlyCost - expense.lessPaymentMonthlyCost
                    : null
                expenses[expense.identifier].ageExpenseDecreases = expense.lessPaymentYear
                    ? expense.lessPaymentYear - new Date().getFullYear() + Number(rootGetters['user/getAge'])
                    : null

                return expenses
            }, {} as Expenses),
        }
    },
    getPostRetirementValues: state => {
        return Object.entries(state.postRetirementValues).map(arr => {
            return {
                yearAfterRetirement: Number(arr[0]),
                income: { ...arr[1] },
            }
        })
    },
    getPostRetirementExpenses: state => {
        return Object.entries(state.postRetirementValues).map(arr => {
            return Object.entries(arr[1].expenses).map(arr => arr[1])
        })
    },
}

export const actions: ActionTree<CalculatorData, RootState> = {
    calcPercentageToGuaranteed({ commit }, ignoreFilter: boolean = false) {
        const currentSavings = this.getters['retirementCalculator/getRetirementSavings']
        const expenses = this.getters['zoomUi/getExpenses']

        const totalExpenses = expenses.reduce((accumulator: number, item: any) => {
            if (ignoreFilter || this.getters['expenses/isGuaranteed'](item.identifier)) {
                return accumulator + item.monthlyCost!
            }

            return accumulator
        }, 0)
        const rawPercentage = 1 - (totalExpenses * 12) / currentSavings

        commit(SET_PERCENTAGE_TO_GUARANTEED, rawPercentage)
    },
    calcIncomes() {
        const expenses = this.getters['zoomUi/getExpenses']

        const guaranteedIncome = expenses.reduce((accumulator: number, item: any) => {
            if (this.getters['expenses/isGuaranteed'](item.identifier)) {
                return accumulator + item.monthlyCost!
            }

            return accumulator
        }, 0)
        const totalExpenses = expenses.reduce((accumulator: number, item: any) => {
            return accumulator + item.monthlyCost!
        }, 0)

        return {
            guaranteedIncome,
            flexibleIncome: totalExpenses - guaranteedIncome,
        }
    },
    setOtherInvestmentValues({ commit }, values) {
        commit(SET_OTHER_INVESTMENT_VALUES, values)
    },
    setOtherIncome({ commit }, age: number) {
        commit(SET_OTHER_INCOME, age)
    },
    setAgeOtherIncomeEnds({ commit }, age: number) {
        commit(SET_AGE_OTHER_INCOME_ENDS, age)
    },
    async calcRetirementSavings({ commit }, { $config, $loading }) {
        const retirementAge = Number(this.getters['user/getRetirementAge'])
        const currentAge = Number(this.getters['user/getAge'])
        const savingsInfo = this.getters['incomes/getSavingsInfo']
        const salaryInfo = this.getters['incomes/getSalaryInfo']

        const extraSavings = Object.values(this.getters['retirementCalculator/getOtherInvestmentValues'])
            .filter(Boolean)
            .reduce((a: any, b) => a + Number(b), 0)

        const currentSavings: number = savingsInfo.afCurrentSavings + extraSavings + savingsInfo.otherCurrentSavings

        const input: RetirementSavingsCalcInput = {
            currentAge,
            retirementage: retirementAge,
            retirementSavings: currentSavings,
            totalCostToCompany: salaryInfo.grossSalary * 12,
            pensionablesalary: salaryInfo.fundSalaryPercentage / 100,
            fixedSalaryContribution:
                12 * savingsInfo.otherMonthlyContribution +
                (savingsInfo.monthlyContributions.unit === SavingsUnit.ABSOLUTE
                    ? savingsInfo.monthlyContributions.amount * 12
                    : 0),
            salaryContributionPercentage:
                savingsInfo.monthlyContributions.unit === SavingsUnit.PERCENTAGE
                    ? savingsInfo.monthlyContributions.amount / 100
                    : 0,
            retirementcontributionfees: savingsInfo.otherFundExpensesPercentage / 100,
        }
        $loading.start()
        const savings = await this.$axios.$post(`${$config.CalcServiceBaseUrl}retirement/savings`, input)
        setTimeout(() => $loading.finish(), 500)
        commit(SET_RETIREMENT_SAVINGS, savings)
    },
    async calcGrossRetirementSavings({ commit }, { $config, $loading }) {
        const input: GrossRetirementSavingsCalcInput = {
            savingsAtRetirement: this.getters['retirementCalculator/getRetirementSavings'],
            retirementAge: Number(this.getters['user/getRetirementAge']),
            gender: String(this.getters['user/getGender']).toLocaleLowerCase(),
        }
        $loading.start()
        const savings = await this.$axios.$post(`${$config.CalcServiceBaseUrl}retirement/savings/gross`, input)
        setTimeout(() => $loading.finish(), 500)
        commit(SET_GROSS_RETIREMENT_SAVINGS, savings)
    },
    async calcNetRetirementSavings({ commit }, { $config, $loading }) {
        const input: NetRetirementSavingsCalcInput = {
            grossIncome: this.getters['retirementCalculator/getGrossRetirementSavings'],
            retirementAge: Number(this.getters['user/getRetirementAge']),
        }
        $loading.start()
        const savings = await this.$axios.$post(`${$config.CalcServiceBaseUrl}retirement/savings/net`, input)
        setTimeout(() => $loading.finish(), 500)

        commit(SET_NET_RETIREMENT_SAVINGS, savings)
    },
    async calcRetirementIncome({ commit }, { $config, $loading }) {
        const input: RetirementIncomeCalcInput = {
            guaranteedIncome: this.getters['retirementCalculator/getGrossRetirementSavings'],
            retirementAge: Number(this.getters['user/getRetirementAge']),
        }
        $loading.start()
        const result = await this.$axios.$post(`${$config.CalcServiceBaseUrl}retirement/income`, input)
        setTimeout(() => $loading.finish(), 500)
        commit(SET_RETIREMENT_INCOME, result)
    },
    async calcMedicalExpesnes({ commit }, { $config, $loading }) {
        const expense = this.getters['expenses/getExpense'](ExpenseIdentifier.Medical)?.originalMonthlyCost

        const input: MedicalExpensesCalcInput = {
            currentAge: Number(this.getters['user/getAge']),
            medicalExpense: expense < 1 ? 1500 : expense,
        }

        $loading.start()
        const savings = await this.$axios.$post(`${$config.CalcServiceBaseUrl}retirement/expenses/medical`, input)
        setTimeout(() => $loading.finish(), 500)

        commit(SET_MEDICAL_EXPENSES, savings)
    },
    async calcPostRetirement({ commit }, { $config, $loading }) {
        const input = this.getters['retirementCalculator/getPostRetirementInput']
        $loading.start()
        const values = await this.$axios.$post(`${$config.CalcServiceBaseUrl}postretirement`, input)
        setTimeout(() => $loading.finish(), 500)

        commit(SET_POST_RETIREMENT_VALUES, values)
    },
}

export const mutations: MutationTree<CalculatorData> = {
    [SET_RETIREMENT_SAVINGS](state, retirementSavings: number) {
        state.retirementSavings = retirementSavings
    },
    [SET_GROSS_RETIREMENT_SAVINGS](state, retirementSavingsGross: number) {
        state.retirementSavingsGross = retirementSavingsGross
    },
    [SET_NET_RETIREMENT_SAVINGS](state, retirementSavingsNet: number) {
        state.retirementSavingsNet = retirementSavingsNet
    },
    [SET_RETIREMENT_INCOME](state, retirementIncome: RetirementIncomeCalcResultRecord[]) {
        state.retirementIncome = retirementIncome
    },
    [SET_MEDICAL_EXPENSES](state, medicalExpenses: { [key: string]: number }) {
        state.medicalExpenses = medicalExpenses
    },
    [SET_OTHER_INCOME](state, value: number) {
        state.otherIncome = value
    },
    [SET_AGE_OTHER_INCOME_ENDS](state, value: number) {
        state.ageOtherIncomeEnds = value
    },
    [SET_OTHER_INVESTMENT_VALUES](state, values) {
        state.otherInvestmentValues = values
    },
    [SET_POST_RETIREMENT_VALUES](state, values) {
        state.postRetirementValues = values
        state.postRetirementValuesExpenses = Object.entries(state.postRetirementValues).map(arr => {
            return Object.entries(arr[1].expenses).map(arr => arr[1])
        })
    },
    [SET_PERCENTAGE_TO_GUARANTEED](state, value) {
        state.percentageToGuaranteed = value
    },
}
