



























import Vue from 'vue'

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            animation: null,
            showPageMask: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(showMe) {
                if (showMe) {
                    this.showPageMask = true
                } else {
                    setTimeout(() => {
                        this.showPageMask = false
                    }, 500)
                }
            },
        },
    },
})
