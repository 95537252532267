<template>
    <div class="footer-navigation">
        <base-button v-if="showPrevious" type="navigator-previous" @click="$emit('previous')">
            <div class="previous-text">
                {{ previousText }}
            </div>
        </base-button>
        <div class="spacer"></div>
        <base-button v-if="showNext" type="navigator-next" @click="$emit('next')">
            <div class="next-text">
                {{ nextText }}
            </div>
        </base-button>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    props: {
        showNext: {
            type: Boolean,
            default: true,
        },
        showPrevious: {
            type: Boolean,
            default: true,
        },
        previousText: {
            type: String,
            default: 'PREVIOUS STEP',
        },
        nextText: {
            type: String,
            default: 'NEXT STEP',
        },
    },
})
</script>

<style scoped>
.footer-navigation {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 77px;
    bottom: 0px;
    left: 0px;
    margin-bottom: 30px;
    transition: opacity 1s ease-in-out;
    opacity: var(--landscape-image-opacity);
}
/** Carefull text positioning to look like style guide. */
.next-text {
    position: absolute;
    left: 30px;
    bottom: 15px;
    font-family: 'Source Sans Pro';
}
.previous-text {
    position: absolute;
    right: 20px;
    bottom: 15px;
    font-family: 'Source Sans Pro';
}
</style>
<style>
/* Without this, hovering over the next button causes scroll-bars to jankily appear. */
body {
    overflow-x: hidden;
}
</style>
