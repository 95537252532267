<template>
    <div
        class="left-version age-year"
        :class="{
            'style-from-here': !overrideCss,
        }"
    >
        <div>
            The year is
            <div class="year">{{ year }}</div>
        </div>
        <div>
            and you are
            <div class="age">{{ age }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        year: {
            type: Number,
            default: new Date().getFullYear(),
        },
        age: {
            type: Number,
            default: null,
        },
        // New prop for if you want to completely take over the css for this component.
        // If this is true, then all the styles in this component are ignored.
        overrideCss: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style>
.style-from-here.left-version.age-year {
    --dark-text-font-size: 36px;
    --emphasised-font-size: 49px;
    position: absolute;
    left: 13%;
    top: 23%;
    width: 350px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    text-align: left;
    font-weight: 600;
    font-size: var(--dark-text-font-size);
    color: #333;
    font-family: 'Source Sans Pro';
    transition: 0.8s opacity ease-in;
    opacity: var(--landscape-image-opacity);
}

.style-from-here.left-version.age-year .year {
    font-size: var(--emphasised-font-size);
    font-weight: 700;
    display: inline-block;
}

.style-from-here.left-version.age-year .age {
    margin: -15px 0;
    font-size: var(--emphasised-font-size);
    font-weight: 700;
    color: #f07f09;
    display: inline-block;
}

@media screen and (max-width: 1400px) {
    .style-from-here.left-version.age-year {
        left: 8%;
    }
}

@media screen and (max-width: 1219px) {
    .style-from-here.left-version.age-year {
        left: 4%;
    }
}

@media screen and (max-width: 1100px) {
    .style-from-here.left-version.age-year {
        left: 2%;
    }
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
    .style-from-here.left-version.age-year {
        font-size: 26px;
    }

    .style-from-here.left-version.age-year .year {
        font-size: 35px;
    }

    .style-from-here.left-version.age-year .age {
        font-size: 35px;
    }
}
</style>
